.editabletext {
    border: solid 0.1rem transparent;
    border-radius: 0.8rem;
    padding: 0.6rem 0.8rem;
    position: relative;
    bottom: 0.7rem;
    cursor: default;

    &__icon {
        position: relative;
        bottom: 0.2rem;
    }
    &--enabled:hover {
        border-color: var(--color-galaxy-300);
        background-color: var(--color-galaxy-50);
        cursor: pointer;
    }

    &__actions {
        gap: 1.6rem;
    }

    &__buttons {
        gap: 0.4rem;
    }

    &__button {
        display: inline-flex;
        border: 0.1rem solid var(--color-galaxy-300);

        &__pencil {
            &:hover {
                background-color: var(--color-meteor-95);
                border-radius: var(--border-radius-l);

                path {
                    fill: var(--color-meteor);
                }
            }
        }
    }

    &__button:hover {
        background-color: var(--color-neptune-100);
    }

    &--with-helper {
        padding-bottom: 0;
    }

    &__helper {
        color: var(--color-galaxy-500);
        font-size: var(--text-size-vs);
        line-height: 1.6rem;

        &--error {
            color: var(--color-galaxy-800);
            font-size: var(--text-size-xs);
            line-height: 1.6rem;
            gap: 0.5rem;
        }
    }

    &--error {
        color: var(--color-mars-50-alt);
    }
}
