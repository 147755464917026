.break-duration-content {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1.2rem;
    padding: 0 1.2rem 1.2rem;
    background-color: var(--color-comet);
    border-radius: var(--border-radius-l);
}

.break-duration-content .optim-config-text-input_container {
    height: auto;
    input {
        width: 100%;
        padding: 0.8rem;
        font-size: var(--text-size-xs);
        line-height: var(--text-size-m);
    }
    .unit-text_element {
        font-size: var(--text-size-xs);
        line-height: var(--text-size-m);
    }
}
