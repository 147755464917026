.rerunschedulermodal {
    padding: 2.4rem;
}

.rerunschedulermodal-item {
    width: 100%;
    border: 0.1rem solid var(--color-galaxy-300);
    border-radius: var(--border-radius-l);
    margin-bottom: 1rem;

    /* Allows us to apply background-color to children elements without having the color spill outside of the border-radius */
    overflow: hidden;
}

/* Target the second element since the first is the hidden input */
.rerunschedulermodal-item > *:nth-child(2) {
    padding-top: 2.4rem;
}

.rerunschedulermodal-item > * {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
}

.rerunschedulermodal-item > *:last-child {
    padding-bottom: 2.4rem;
}

.rerunschedulermodal-maincontent {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    padding-bottom: 1.6rem;
}

.rerunschedulermodal-subcontent {
    background-color: var(--color-galaxy-50);
    padding-top: 1.2rem;
}

.rerunschedulermodal-subcontent .subcontent-header {
    margin-bottom: 0.8rem;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2rem;

    color: var(--color-galaxy-500);
}

.rerunschedulermodal-subcontent .subcontent-checkboxsection {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 0.8rem;
}

.rerunschedulermodal-subcontent .subcontent-checkboxdescription {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2rem;
}

.rerunschedulermodal-subcontent .subcontent-checkboxmaindescription {
    color: var(--color-galaxy-800);
}

.rerunschedulermodal-subcontent .subcontent-checkboxsubdescription {
    color: var(--color-galaxy-500);
}

.rerunschedulermodal-item:hover {
    background-color: var(--color-neptune-100);
    border-color: var(--color-neptune);
}

.rerunschedulermodal-item_selected {
    background-color: var(--color-comet);
    border-color: var(--color-ocean);
}
.rerunschedulermodal-title {
    font-size: var(--text-size-m);
    font-weight: 500;
    color: var(--color-galaxy-800);
    line-height: 2.4rem;
}
.rerunschedulermodal-status {
    background-color: var(--color-venus-100);
    color: var(--color-venus);
    font-weight: 500;
    font-size: var(--text-size-s);
    line-height: 2rem;
    width: 7.2rem;
    padding: 0.1rem;
    text-align: center;
    border-radius: var(--border-radius-l);
    margin-left: 1rem;
}

.rerunschedulermodal-description {
    font-size: var(--text-size-s);
    font-weight: 400;
    color: var(--color-galaxy-500);
    line-height: 2rem;
}

.rerunschedulermodal-radio {
    cursor: pointer;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    border: 0.1rem solid var(--color-ocean);
}

.rerunschedulermodal-item_selected .rerunschedulermodal-radio {
    border: 0.6rem solid var(--color-sky);
}

.rerunschedulermodal-footer {
    width: 100%;
    height: 10rem;
    padding: 2.4rem;
    background-color: var(--color-galaxy-50);
    border-radius: var(--border-radius-l);
}
