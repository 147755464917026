.notes-tab_content {
    & .notes-tab_header-container {
        display: flex;
        flex-direction: column;
        row-gap: 0.2rem;
    }

    & .notes-tab_notes-container {
        background-color: var(--color-galaxy-50);
        border-radius: 0.6rem;
        padding: 4rem 5.2rem;
        margin-top: 2rem;

        display: flex;
        flex-direction: column;
        row-gap: 1rem;

        & .closed-note-container:hover,
        & .closed-note-container:focus,
        & .open-note-container:hover,
        & .open-note-container:focus {
            border: 0.1rem solid var(--color-meteor);
        }

        & .open-note-container,
        & .closed-note-container {
            border: 0.1rem solid transparent;
            padding: 1.6rem 3.2rem;
            border-radius: 0.8rem;
        }

        & .open-note-container {
            background-color: white;

            display: grid;
            grid-template-rows: auto 1fr;
            row-gap: 0.2rem;
            min-height: 28rem;

            & .note-title-input {
                all: unset;

                // No direct match in `typeography.scss`, closest match is _header-3
                color: var(--color-galaxy-800);
                font-weight: 500;
                font-size: var(--text-size-m);
                line-height: 2.4rem;

                width: 100%;
            }

            & .note-content-textarea {
                all: unset;

                // No direct match in `typeography.scss`, no close match
                color: var(--color-galaxy-500);
                font-weight: 400;
                font-size: var(--text-size-s);
                line-height: 2rem;

                width: 100%;
            }
        }

        & .closed-note-container {
            background-color: white;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            & .closed-note-left-container,
            & .closed-note-right-container {
                display: flex;
                flex-direction: column;
                row-gap: 0.4rem;
            }

            & .closed-note-left-container {
                overflow: hidden;

                & .closed-note-title {
                    /* No direct match in typeography, closest is _header-4 */
                    color: var(--color-galaxy-700);
                    font-weight: 500;
                    font-size: var(--text-size-s);
                    line-height: 2rem;

                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                & .closed-note-content {
                    /* No direct match in typeography, no close match */
                    color: var(--color-galaxy-400);
                    font-weight: 400;
                    font-size: var(--text-size-s);
                    line-height: 2rem;

                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            & .closed-note-right-container {
                align-items: flex-end;
            }
        }
    }

    & .add-new-note-btn-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        & .add-new-note-button {
            & .button-layout {
                display: flex;
                flex-direction: row;
                align-items: center;
                column-gap: 0.4rem;
                color: var(--color-meteor);
            }
        }
    }
}
