.optim-config-text-input_container {
    position: relative;
    height: 4rem;
}

.unit-text_container {
    position: absolute;
    right: 1rem;
    top: 0;
    bottom: 0;
    z-index: var(--z-index-inputfield);
    width: 10rem;
    height: 100%;
    justify-content: right;
    .unit-text_element {
        color: var(--color-galaxy-400);
        font-size: var(--text-size-m);
        line-height: 2.4rem;
    }
}

.optim-config-text-input_input {
    width: 100%;
    height: 100%;
    border: 0.1rem solid var(--color-galaxy-300);
    border-radius: var(--border-radius-l);
    font-size: var(--text-size-m);
    padding: 0 1.2rem;
    z-index: var(--z-index-inputfield-overlay);
    &:read-only {
        color: var(--color-galaxy-400);
        background-color: var(--color-galaxy-100);
        border: 0.1rem solid var(--color-galaxy-400);
    }
    &::placeholder {
        color: var(--color-galaxy-400);
        font-size: var(--text-size-m);
        line-height: 2.4rem;
    }
}

.optim-config-text-input_input:focus {
    outline: none;
    border-color: var(--color-ocean);
    background-color: var(--color-neptune-100);
}

.optim-config-text-input_input:disabled {
    background-color: var(--color-galaxy-100);
    cursor: not-allowed;
}

.optim-config-text-input--error {
    border-color: var(--color-mars);
}

.optim-config-text-input--error:focus {
    border-color: var(--color-mars);
    background-color: var(--color-mars-100);
}

/* Chrome and Safari: Hides arrows for number input field */
.optim-config-text-input_input::-webkit-outer-spin-button,
.optim-config-text-input_input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox: Hides arrows for number input field */
.optim-config-text-input_input[type='number'] {
    -moz-appearance: textfield;
}
