.address-tab-content {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    column-gap: 2.4rem;
    row-gap: 2rem;

    &__date-container {
        grid-column-start: 1;
        grid-column-end: 4;
    }

    &__date-label {
        column-gap: 0.4rem;
        margin-bottom: 0.8rem;
    }

    &__datepicker .react-datepicker__triangle {
        display: none;
    }

    &__radio-group-wrapper {
        grid-column-start: 4;
        grid-column-end: 7;

        & .radio-group__label {
            font-weight: 500;
        }
    }

    &__location-radio-group-wrapper {
        grid-column-start: 1;
        grid-column-end: 4;

        & .radio-group__label {
            font-weight: 500;
        }
    }

    &__radio-group {
        column-gap: 4rem;

        & .radio-group__radio-label {
            column-gap: 0.4rem;
            font-weight: 400;
            white-space: nowrap;
        }
    }

    &__radio-input {
        color: var(--color-sky);
    }

    &__divider {
        grid-column-start: 1;
        grid-column-end: 7;
        height: 0;
        border-top: 0.1rem solid var(--color-galaxy-200);
        margin: 0.8rem 0;
    }

    &__customer-name {
        grid-column-start: 1;
        grid-column-end: 4;
    }

    &__external-task-type {
        grid-column-start: 4;
        grid-column-end: 7;
        .dropdown {
            width: 100%;
        }
    }

    &__customer-id {
        grid-column-start: 4;
        grid-column-end: 7;
    }

    &__show-to-driver-switch {
        grid-column-start: 1;
        grid-column-end: 4;
        display: flex;
        align-items: center;

        span {
            font-weight: normal !important;
            margin-left: 1rem;
            font-size: var(--text-size-m) !important;
        }
    }

    .google-places-autocomplete,
    .places-autocomplete {
        grid-column-start: 1;
        grid-column-end: 4;
    }

    .customer-database-autocomplete {
        & .fuzzytextsearch {
            width: inherit;
        }

        .fuzzytextsearch-input {
            height: 3.4rem;
        }

        .fuzzytextsearch-results {
            top: unset;
        }

        grid-column-start: 1;
        grid-column-end: 4;
    }

    &__address-line-1 {
        grid-column-start: 1;
        grid-column-end: 7;
    }

    &__address-line-2 {
        grid-column-start: 1;
        grid-column-end: 7;
        margin-top: -0.8rem;
    }

    &__city {
        grid-column-start: span 2;
    }

    &__state {
        grid-column-start: span 2;
    }

    &__zipcode {
        grid-column-start: span 2;
    }

    &__collapsible {
        grid-column-start: 1;
        grid-column-end: 7;

        .collapsible-toggle_button {
            justify-content: start;
            padding: 0;

            .collapsible-toggle_text {
                padding: 0 0.8rem;
            }
        }

        .collapsible-panel {
            padding: 0;
            margin-top: 1.2rem;
        }
    }

    &__coordinates {
        column-gap: 2.4rem;
        margin-top: 2rem;
    }

    &__map-button {
        font-size: var(--text-size-m);
        padding: 0.8rem 1rem;
        margin: 0.8rem 0;
        grid-column-start: 1;
        grid-column-end: 3;
    }

    &__map {
        grid-column-start: 1;
        grid-column-end: 7;
        .customermap.map {
            height: 32rem;
        }
    }
}
