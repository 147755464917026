.dropdown-with-inputbox {
    & input {
        border-radius: 0 0.6rem 0.6rem 0;
        border-left: hidden;
    }

    &__input-container {
        justify-content: end;
        font-size: var(--text-size-s);
        color: var(--color-galaxy-300);

        &-unit {
            padding: 1rem;
        }
    }

    &__separator {
        height: 3.2rem;
        border-right: 0.1rem solid var(--color-galaxy-300);

        &--focus {
            border-color: var(--color-ocean);
        }
    }
}

.dropdown-with-inputbox__outline {
    border: 0.1rem solid var(--color-galaxy-300);
    border-right: hidden;
    border-radius: 0.6rem 0 0 0.6rem;
    background-color: transparent;
    padding: 0.8rem 1rem 0.8rem 1.4rem;
    height: 3.2rem;
    &:hover {
        background-color: var(--color-neptune-100);
        border-color: var(--color-ocean);
    }
}
