.long-shift-block {
    margin-bottom: 1rem;
    border-radius: var(--border-radius-m);
    padding: 1rem;

    &__remove {
        display: none;
        right: 2rem;
        top: -1rem;
    }

    &:hover {
        background: var(--color-neptune-200);
    }

    &:hover &__remove {
        display: flex;
    }

    &__shift-time {
        width: 26rem;
        border: 0.1rem solid var(--color-galaxy-300);
        background: var(--color-white);
    }

    &__shift-time,
    .optim-config-text-input_container {
        height: 3.44rem;
    }

    &__shift-time,
    .optim-config-text-input_input {
        border-radius: var(--border-radius-m);
        padding: 0.6rem 1.2rem;
    }

    &__time-and-duration {
        gap: 1.6rem;
    }

    &__hours-and-minutes {
        gap: 0.8rem;
    }
}
