.stacked-equipment-pin-popup {
    width: 27.1rem;
    height: 8rem;
    border-radius: var(--border-radius-l);
    z-index: var(--z-index-toast);

    position: absolute;

    &__stacked-equipment {
        background-color: var(--color-galaxy-99);
        overflow-x: auto;
        overflow-y: hidden;

        white-space: nowrap;

        padding: 0.8rem;
        border-radius: var(--border-radius-l) var(--border-radius-l) 0 0;
        border-bottom: 0.1rem solid var(--color-galaxy-90);
        height: 4.8rem;

        display: flex;
        gap: 1.2rem;
        align-items: center;

        color: var(--color-galaxy-30);
        font-size: var(--text-size-s);
        font-weight: var(--font-weight-medium);
        line-height: 2rem;
        letter-spacing: var(--letter-spacing-xxxs);

        & .stopmarker {
            transform: none;
        }

        & .stopmarker-body {
            width: 3.2rem;
            height: 3.2rem;

            &:hover {
                cursor: pointer;
            }
        }

        & .stopmarker-circle {
            width: 3.2rem;
            height: 3.2rem;
        }

        &__scrollable {
            height: 6.8rem;
        }
    }

    &__scroll-text {
        background-color: var(--color-galaxy-100);
        padding: 0.8rem;
        border-radius: 0 0 var(--border-radius-l) var(--border-radius-l);

        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.4rem;

        color: var(--color-galaxy-500-alt);
        text-align: center;
        font-size: var(--text-size-vxxs);
        font-weight: var(--font-weight-medium);
        line-height: 1.6rem;
        letter-spacing: var(--letter-spacing-l);

        & .icon-button {
            width: 1.8rem;
            height: 1.5rem;
            align-items: end;
        }
    }

    &__stack-pin {
        width: 3.2rem;
        min-width: 3.2rem;
        height: 3.2rem;

        display: flex;
        align-items: center;
        justify-content: center;

        outline: none;
        border: none;
        background-color: var(--color-transparent);

        cursor: pointer;
    }

    & .equipment-marker {
        transform: none;

        width: 3.2rem;
    }
}
