.route-card-export-pill {
    background-color: var(--color-meteor-40);
    border-radius: var(--border-radius-m);
    display: flex;
    padding: 0.2rem 0.4rem;
    align-items: center;
    gap: 0.4rem;

    &__text {
        color: var(--color-comet);
        font-size: var(--text-size-vxxs);
        font-weight: var(--font-weight-medium);
        line-height: 1.6rem;
        letter-spacing: var(--letter-spacing-l);
    }
}
