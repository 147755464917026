.compartment-usage-details {
    min-width: 0;
    display: flex;
    padding: 0.2rem 0.8rem;
    justify-content: space-between;
    color: var(--color-galaxy-800);
    line-height: 2rem;
}

.compartment-usage-details__label {
    font-size: 1.1rem;
    font-weight: 500;
    flex-basis: 40%;
    min-width: 0;
    flex-grow: 1;
}

.compartment-usage-details__summary {
    font-size: var(--text-size-s);
    flex-basis: 60%;
    text-align: right;
    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &--over-capacity {
        color: var(--color-mars-50-alt);
    }
}
