._cg-1 {
    column-gap: 0.2rem;
}
._cg-2 {
    column-gap: 0.8rem;
}
._cg-4 {
    column-gap: 1.6rem;
}
._cg-6 {
    column-gap: 2.4rem;
}
._cg-8 {
    column-gap: 3.2rem;
}
