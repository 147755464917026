/* CSS for react-tabs */
.react-tabs {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.react-tabs__tab-list {
    margin: 0;
    padding: 0;
    display: flex;
}

.react-tabs__tab {
    list-style: none;
    height: 4.4rem;
    align-content: center;
    cursor: pointer;
    color: var(--color-galaxy-800);
    background-color: var(--color-galaxy-100);
    border-top: 0.3rem solid transparent;
    border-bottom: 0.1rem solid var(--color-galaxy-200);
    font-weight: 400;
    font-size: var(--text-size-s);
    letter-spacing: var(--letter-spacing-s);
    line-height: 2rem;
    text-align: center;
    width: 100%;
}

/* applying border left to all buttons except the first, the selected and the adjacent sibling of the selected */
.react-tabs__tab:nth-child(n
        + 2):not(.react-tabs__tab--selected):not(.react-tabs__tab--selected
        + .react-tabs__tab) {
    background-image: linear-gradient(
        var(--color-galaxy-200),
        var(--color-galaxy-200)
    );
    background-repeat: no-repeat;
    background-size: 0.1rem 1.2rem;
    background-position: left center;
}

.react-tabs__tab:hover:not(.react-tabs__tab--disabled) {
    background-color: var(--color-galaxy-50);
}

.react-tabs__tab--selected {
    border-top: 0.3rem solid var(--color-meteor);
    border-bottom: 0.1rem solid transparent;
    background-color: var(--color-comet);
    font-weight: 500;

    &:hover {
        background-color: var(--color-galaxy-50);
    }
}

.react-tabs__tab--disabled {
    cursor: default;
}
