@import 'src/ui/mixins/stopscontrol-childmodal';

.stop-selection-content {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1.2rem;
    padding: 1.2rem;
    background-color: var(--color-comet);
    border-radius: var(--border-radius-l);
    margin-bottom: 0.4rem;

    span {
        max-width: 17.6rem;
    }
}

.stop-search-field {
    position: relative;
    overflow: visible;

    input {
        width: 100%;
        padding: 0.8rem;
        font-size: var(--text-size-xs);
        line-height: var(--text-size-m);
    }
}

.stop-search-field__dropdown {
    @include stopcontrol-childmodal-search-dropwdown;
    width: 100%;
    z-index: var(--z-index-inputfield-overlay);
}
