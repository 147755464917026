.create-trip-modal {
    min-width: unset;
    width: 45.5rem;
    min-height: 37.492rem;

    &__content {
        padding: 2.4rem 3.2rem;
        gap: 1.6rem;
    }

    &__info {
        margin-top: 1.6rem;
        gap: 1.6rem;
    }

    &__description,
    &__confirmation {
        font-size: var(--text-size-s);
        font-weight: var(--font-weight-normal);
        line-height: 2rem;
        letter-spacing: var(--letter-spacing-s);
        color: var(--color-galaxy-800-alt);
    }

    &__confirmation {
        font-weight: var(--font-weight-bold);
    }

    &-footer {
        padding: 1.6rem 2.4rem;
        gap: 1.2rem;
        background-color: var(--color-comet);
        border-top: 0.1rem solid var(--color-galaxy-90);
        margin-top: auto;
        border-bottom-left-radius: var(--border-radius-l);
        border-bottom-right-radius: var(--border-radius-l);

        &__confirm {
            min-width: 8.8rem;
        }

        &__cancel {
            min-width: 8rem;
        }
    }
}
