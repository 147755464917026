.routecard-text {
    font-size: var(--text-size-s);
    line-height: 2rem;
    color: var(--color-galaxy-800);
    font-weight: 400;

    .icon-button {
        height: initial;
        width: initial;
        align-self: flex-start;
    }

    .routecard-text_group {
        span {
            margin-left: 0.6rem;
            letter-spacing: var(--letter-spacing-s);
            color: var(--color-galaxy-800);
        }
    }
}
