@import 'src/ui/mixins/scrollbar';

.drawer {
    background-color: var(--color-white);
    width: var(--dimension-drawer-width);
    height: 100%;
    border-left: 0.1rem solid var(--color-galaxy-200);
}

.drawer:first-of-type {
    border-left: 0;
}

.drawer_closed {
    width: var(--dimension-closed-drawer-width);
}

.drawer_closed:hover {
    background-color: var(--color-galaxy-50);
}

.drawer-header {
    flex-shrink: 0;
    min-height: var(--dimension-drawer-header-min-height);

    .drawer_closed & {
        display: flex;
        justify-content: center;
        padding-left: 0;
        padding-right: 0;
        border-bottom: 0;
    }
}

.drawer-header_dark {
    background-color: var(--color-galaxy-800);
    color: var(--color-comet);
    opacity: 0.8;
}

.drawer-body {
    flex-grow: 1;
    height: var(--dimension-drawer-body-min-height);
    overflow-y: auto;

    @include thin-scrollbar;
}

.drawer-footer {
    min-height: var(--dimension-drawer-footer-min-height);
    flex-shrink: 0;
    .drawer-body + & {
        border-top: 0.1rem solid var(--color-galaxy-200);
        padding: 0 1.2rem;
    }
}

.drawer-title {
    color: var(--color-galaxy-800);
    font-size: var(--text-size-s);
    line-height: 2rem;
    width: 100%;
}

.drawer-title_dark {
    color: var(--color-comet);
}

.drawer-button {
    display: inline-flex;
}

.drawer-button_on,
.drawer-button:hover {
    background-color: var(--color-neptune-100);
}

.drawer-button_transparent,
.drawer-button_transparent:hover {
    background-color: transparent;
}

.drawer-buttongroup {
    column-gap: 0.4rem;
}

.drawer-option {
    padding: 1.2rem 1.6rem;
    background-color: var(--color-galaxy-50);
}

.drawer-sort {
    min-height: var(--dimension-drawer-sort-min-height);
}

.drawer-filter {
    min-height: var(--dimension-drawer-filter-min-height);
    grid-template-columns: repeat(11, 1fr);
}
