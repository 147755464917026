.customer-map-marker {
    height: 2rem;
    width: 2rem;
    border-radius: var(--border-radius-round);
    background-color: var(--color-ocean);
    border: 0.2rem solid var(--color-comet);
    position: relative;
    bottom: 1rem;
    right: 1rem;
    cursor: grab;

    &__selected {
        background-color: var(--color-venus-400);
        cursor: grabbing;
    }
}
