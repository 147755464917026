.submenu-main {
    margin-top: 0.5rem;
    padding: 1rem;
    margin-left: 4.6rem;
    margin-right: 2rem;
    background-color: var(--color-galaxy-50);
    border-radius: var(--border-radius-l);
}

.submenu-option {
    margin-top: 0.5rem;
}

.submenu-radio {
    cursor: pointer;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
}

.submenu-maincontent {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    padding-bottom: 1rem;
}
