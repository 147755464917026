.collapsible-toggle_button {
    width: 100%;
    padding: 0 1.6rem;
    margin: 0;
    background-color: transparent;
    border: none;

    &:hover {
        cursor: pointer;
        background-color: inherit;
    }

    &[disabled] {
        cursor: not-allowed;
    }
}

.collapsible-toggle__cancel-button {
    transform: translateX(1rem);
}

.collapsible-toggle_text {
    font-size: var(--text-size-s);
    line-height: 2rem;
    color: var(--color-meteor);

    [disabled] & {
        color: var(--color-galaxy-300);
    }
}

.collapsible-panel {
    padding: 0 1.6rem;
    max-height: 0;
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s;
    overflow: hidden;

    &--open {
        max-height: 120rem;
    }
}
