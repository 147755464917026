._rg-1 {
    row-gap: 0.2rem;
}
._rg-2 {
    row-gap: 0.4rem;
}
._rg-3 {
    row-gap: 0.6rem;
}
._rg-4 {
    row-gap: 0.8rem;
}
._rg-8 {
    row-gap: 1.6rem;
}
._rg-12 {
    row-gap: 2.4rem;
}
._rg-16 {
    row-gap: 3.2rem;
}
