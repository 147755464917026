.places-autocomplete {
    & .fuzzytextsearch {
        width: 100%;
    }

    & .fuzzytextsearch-results {
        top: 3.5rem;
    }

    &__result {
        gap: 0.2rem;
        padding: 0.8rem 0.5rem;
    }

    &__icon {
        flex-basis: border-box;
        flex-grow: 0;
        flex-shrink: 0;
    }

    &__select-button {
        outline: none;
        background: none;
        border: none;
        text-align: left;

        &:focus,
        &:hover {
            background: var(--color-galaxy-100);
        }
    }
}

.places-autocomplete__input {
    & .fuzzytextsearch-input {
        height: 3.5rem;
    }
}
