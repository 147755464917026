.create-trip-modal-route-card {
    padding: 1.2rem;
    gap: 1.2rem;
    border-radius: var(--border-radius-l);
    background-color: var(--color-galaxy-50);

    &__number-of-trips {
        font-size: var(--text-size-s);
        font-weight: var(--font-weight-normal);
        line-height: 2rem;
        letter-spacing: var(--letter-spacing-s);
        margin-left: auto;
        color: var(--color-galaxy-800);
    }

    &__summary {
        gap: 0.4rem;
        grid-template-columns: repeat(3, 1fr);
    }

    .routecard-metric {
        line-height: 2rem;
        font-size: var(--text-size-s);
        color: var(--color-galaxy-800);
        font-weight: var(--font-weight-normal);
    }
}
