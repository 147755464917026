.label {
    color: var(--color-galaxy-500);
    border: 0.1rem solid var(--color-galaxy-300);
    border-radius: var(--border-radius-m);
    padding: 0.2rem 0.8rem;
}
.marker-popup__label {
    max-width: 10rem;
}
.tooltip {
    word-break: break-all;
}
