$button-padding-x: 0.8rem;
$divider-height: 0.1rem;
$divider-position-left: $button-padding-x;
$divider-position-right: $button-padding-x;

.stopmodalmenubutton {
    padding: 0.4rem;

    // creates a divider between buttons
    // divider is indented left and right by the buttons `x` padding
    & + & {
        margin-top: $divider-height;
        position: relative;

        &:before {
            content: '';
            display: block;
            position: absolute;
            background-color: var(--color-galaxy-200);
            height: $divider-height;
            top: -$divider-height;
            left: $divider-position-left;
            right: $divider-position-right;
        }
    }
}

.stopmodalmenubutton__button {
    padding: 0.8rem;
    width: 100%;
    height: 3.2rem;
    border: none;
    background-color: var(--color-comet);
    border-radius: var(--border-radius-l);

    &:hover {
        background-color: var(--color-neptune-100);
    }

    &:disabled {
        &,
        &:hover {
            background-color: var(--color-comet);

            // makes only the internal contents look disabled
            & > span {
                opacity: 0.5;
            }
        }
    }
}

.stopmodalmenubutton__button-text {
    column-gap: 1.2rem;
    color: var(--color-meteor);
}

.stopmodalmenubutton__button-stop {
    color: var(--color-galaxy-500);
}
