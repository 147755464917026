// padding
._p-0 {
    padding: 0 !important;
}
._p-1 {
    padding: 0.4rem;
}
._p-2 {
    padding: 0.8rem;
}
._p-4 {
    padding: 1.2rem;
}
._p-5 {
    padding: 2rem;
}
._p-6 {
    padding: 2.4rem;
}
._p-12 {
    padding: 4.8rem;
}
._p-20 {
    padding: 8rem;
}

// padding top
._pt-0 {
    padding-top: 0;
}
._pt-1 {
    padding-top: 0.4rem;
}
._pt-2 {
    padding-top: 0.8rem;
}
._pt-4 {
    padding-top: 1.2rem;
}
._pt-5 {
    padding-top: 2rem;
}
._pt-6 {
    padding-top: 2.4rem;
}
._pt-12 {
    padding-top: 4.8rem;
}
._pt-20 {
    padding-top: 8rem;
}

// padding right
._pr-0 {
    padding-right: 0;
}
._pr-1 {
    padding-right: 0.4em;
}
._pr-2 {
    padding-right: 0.8rem;
}
._pr-4 {
    padding-right: 1.2rem;
}
._pr-5 {
    padding-right: 2rem;
}
._pr-6 {
    padding-right: 2.4rem;
}
._pr-12 {
    padding-right: 4.8rem;
}
._pr-20 {
    padding-right: 8rem;
}

// padding bottom
._pb-0 {
    padding-bottom: 0;
}
._pb-1 {
    padding-bottom: 0.4rem;
}
._pb-2 {
    padding-bottom: 0.8rem;
}
._pb-4 {
    padding-bottom: 1.2rem;
}
._pr-5 {
    padding-right: 2rem;
}
._pb-6 {
    padding-bottom: 2.4rem;
}
._pb-12 {
    padding-bottom: 4.8rem;
}
._pb-20 {
    padding-bottom: 8rem;
}

// padding left
._pl-0 {
    padding-left: 0;
}
._pl-1 {
    padding-left: 0.4rem;
}
._pl-2 {
    padding-left: 0.8rem;
}
._pl-4 {
    padding-left: 1.2rem;
}
._pl-5 {
    padding-left: 2rem;
}
._pl-6 {
    padding-left: 2.4rem;
}
._pl-12 {
    padding-left: 4.8rem;
}
._pl-20 {
    padding-left: 8rem;
}
