@import 'src/ui/mixins/mapmarker';

.stopmarker {
    @include mapmarker;
}

.stopmarker-circle {
    @include mapmarker-circle($circle-parent-name: stopmarker);
    box-shadow: unset;
}

.stopmarker-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 3.6rem;
    height: 3.6rem;
}

.stopmarker-label {
    @include mapmarker-label($circle-parent-name: stopmarker);
}

.stopmarker__icon {
    &.icon--priority {
        top: 2rem;
        left: 2rem;
    }

    &.icon--default {
        top: 1.2rem;
        left: 1.4rem;
        width: 1.5rem;
    }
}

.stopmarker-star {
    @include mapmarker-star();
}

.stopmarker-star_number {
    @include mapmarker-star-number(
        $font-size: 1.4rem,
        $text-y-translate: -2.5rem
    );
    height: 0;
}

.stopmarker_selected {
    .stopmarker-body {
        border-radius: var(--dimension-round);
        border: 0.2rem solid var(--color-black);
        background-color: rgba(255, 255, 255, 0.8); // comet, 80% opacity
    }

    &.stopmarker-star .stopmarker-body {
        padding: 0;
    }
}
