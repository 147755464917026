.manage-stop-control-list-item {
    column-gap: 0.9rem;
    width: 100%;

    & + & {
        margin-top: 0.8rem;
    }

    > div:first-of-type {
        max-width: 9.8rem;
        gap: 0.4rem;
    }

    > button {
        background-color: var(--color-comet);
        border-radius: var(--dimension-round);
        width: 2.4rem;
        height: 2.4rem;
    }
}

.manage-stop-control-list-item__task-type {
    padding: 0.2rem 0.8rem;
    height: fit-content;
    background: var(--color-galaxy-100);
    border-radius: var(--border-radius-xxl);
    right: 0;
    top: -0.1rem;

    span {
        font-weight: var(--font-weight-medium);
        font-size: var(--text-size-vxxs);
        line-height: 1.6rem;
        letter-spacing: var(--letter-spacing-l);
        color: var(--color-galaxy-800-alt);
    }
}
