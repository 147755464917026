.duplicate-task-modal-choose-assignment {
    padding: 2rem 2.4rem;
    border-radius: var(--border-radius-l);
    box-shadow: 0 0.1rem 0.3rem 0 var(--color-box-shadow);
    background-color: var(--color-comet);

    &__title {
        font-size: var(--text-size-m);
        font-weight: var(--font-weight-medium);
        line-height: 2.4rem;
        letter-spacing: var(--letter-spacing-xxs);
    }

    > .radio-group {
        row-gap: 1.2rem;
    }

    .radio-group__radio-input-group {
        flex-direction: column;
        gap: 0.8rem;

        & .radio-group__radio-label {
            gap: 0.8rem;
            font-size: var(--text-size-m);
            font-weight: var(--font-weight-normal);
            line-height: 2.4rem;
        }
    }
}
