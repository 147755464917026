.driver-break-modal-main-view__body {
    row-gap: 1.2rem;

    .icon_delete + span {
        color: var(--color-mars-500);
    }
}

.driver-break-modal-main-view__header {
    > button {
        margin-left: auto;
    }
}

.driver-break-modal-main-view__menu {
    background-color: var(--color-comet);
    border-radius: var(--border-radius-l);
    overflow: hidden;
}
