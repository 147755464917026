.marker-popup {
    position: absolute;
    bottom: 2rem;
    left: 0;
    transform: translate(-50%, 0);
    width: 27rem;
    padding: 1.6rem;
    background-color: var(--color-comet-alpha-70);
    border-radius: var(--border-radius-l);
    backdrop-filter: blur(2.4rem);
    row-gap: 1.2rem;
}

/*fallback for Firefox (not supporting backdrop-filter) */
@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .marker-popup {
        background-color: rgb(248, 247, 245, 1);
    }
}

.marker-popup:hover {
    cursor: default;
}

.marker-popup-header {
    font-size: var(--text-size-s);
    color: var(--color-galaxy-800);
    text-transform: capitalize;
    row-gap: 1.2rem;

    &__chips {
        .machine-learning-score-chip {
            margin-left: auto;
        }
    }
}

.marker-popup-body {
    row-gap: 1.2rem;

    .detail {
        margin: 0;
        font-size: var(--text-size-s);
        color: var(--color-galaxy-800);
        line-height: 2rem;
    }

    .icon {
        flex-shrink: 0;
    }

    .icon_union {
        position: relative;
        left: 0.4rem;
        top: 0.4rem;
    }

    button {
        height: 3.2rem;
        font-size: var(--text-size-s);
    }

    .ml-service-time-chip {
        height: 2rem;
        padding: 0.2rem 0.8rem;
        background-color: var(--color-comet);
        border-radius: var(--border-radius-xxl);
        white-space: nowrap;
        font-size: var(--text-size-xs);
        font-weight: var(--font-weight-medium);
        line-height: 1.6rem;
        letter-spacing: var(--letter-spacing-m);
        text-align: center;
        align-self: start;
    }
}

.completed-stop-popup {
    &.marker-popup {
        width: 27.2rem;
        background-color: var(--color-comet-alpha-70);
        row-gap: var(--icon-xs);
        padding: var(--icon-s);
        backdrop-filter: blur(2.4rem);
    }
    & .marker-popup-header {
        row-gap: var(--icon-xs);
    }
    & .marker-popup-body {
        display: grid;
        grid-auto-rows: min-content;

        row-gap: var(--icon-s);

        > :nth-child(6) {
            margin-top: -0.4rem;
        }
    }
    & .service-time {
        display: flex;
        justify-content: space-between;
    }
    & .detail-secondary {
        color: var(--color-galaxy-800);
    }
}
