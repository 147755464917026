.duplicate-task-modal-header {
    background-color: var(--color-comet);
    padding: 1.2rem 2rem 1.2rem 1.2rem;
    gap: 1.2rem;
    border-radius: var(--border-radius-l);

    &__title {
        font-size: var(--text-size-m);
        font-weight: var(--font-weight-medium);
        line-height: 2.4rem;
        letter-spacing: var(--letter-spacing-xxs);
        color: var(--color-galaxy-800-alt);
    }

    &__address {
        font-size: var(--text-size-xs);
        font-weight: var(--font-weight-normal);
        line-height: 1.6rem;
        letter-spacing: var(--letter-spacing-m);
        color: var(--color-galaxy-800-alt);
    }
}
