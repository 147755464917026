@import 'src/ui/mixins/mapmarker';

.livestopmarker {
    @include mapmarker;

    position: relative;
}

.livestopmarker__circle {
    @include mapmarker-circle($circle-parent-name: livestopmarker);

    font-size: var(--text-size-xxs);
    font-weight: 500;
    color: var(--color-galaxy-500);
    background-color: var(--color-comet);
}

.livestopmarker__label {
    @include mapmarker-label($circle-parent-name: livestopmarker);
}

.livestopmarker__canceled {
    width: 2.5rem;
    height: 2.5rem;
}

.livestopmarker--atrisk {
    color: var(--color-galaxy-800);
    background-color: var(--color-saturn);
}

.livestopmarker--late {
    color: var(--color-comet);
    background-color: var(--color-mars);
}

.livestopmarker--completed {
    color: var(--color-galaxy-500);
    background-color: var(--color-comet);
}

.livestopmarker__priority {
    @include mapmarker-star();
}

.livestopmarker__priority-text {
    @include mapmarker-star-number($text-x-translate: -50%);
    position: absolute;
}

.livestopmarker__icon {
    &.icon-priority {
        top: 1.6rem;
        left: 1.7rem;
    }
}

.livestopmarker--selected {
    .livestopmarker__icon-group {
        border-radius: var(--dimension-round);
        border: 0.2rem solid var(--color-black);
        background-color: rgba(255, 255, 255, 0.8); // comet, 80% opacity
        padding: 0.5rem;
    }
}

.livestopmarker.livestopmarker__canceled-default {
    width: 2.3rem;
    height: 2.3rem;
}

.livestopmarker__canceled-priority {
    width: 2.8rem;
    height: 2.8rem;

    svg {
        fill: var(--color-comet);
    }
}

.livestopmarker__icon-dash {
    transform: translate(0, 0.8rem);
    position: absolute;
}
