.formiktextinputfield {
    grid-auto-flow: row;
    grid-auto-rows: 2.4rem max-content;
    grid-gap: 0.4rem;
    margin: 0;

    > div {
        gap: 0.4rem;
    }

    .formiktextinputfield-title {
        color: var(--color-galaxy-800);
        font-size: var(--text-size-m);
        line-height: 2.4rem;
    }
}

.formik-text-input-field__asterisk {
    color: var(--color-ocean);
    font-size: var(--text-size-m);
}
