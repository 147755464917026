.stack-pin-marker {
    & .stacked-stop-pin-popup {
        top: -10.25rem;
        left: -13rem;

        &__scrollable {
            top: -12.25rem;
            height: 10rem;
        }
    }

    .stack-cluster-count {
        position: absolute;

        top: -0.2rem;
        right: 0.3rem;
        z-index: var(--z-index-modal);
        padding: 0 0.4rem;
        color: var(--color-white);
        background-color: var(--color-galaxy-800-alt);
        border-radius: var(--border-radius-xxl);

        font-size: var(--text-size-vxxs);
        opacity: 1;
    }
}

.unassigned-stack-pin {
    &.stopmarker {
        position: relative;

        & .stopmarker-body {
            line-height: normal;

            width: 4.8rem;
            height: 3.6rem;

            &__selected {
                border: 0.2rem solid var(--color-galaxy-800-alt);
                border-radius: var(--border-radius-cluster);
                background-color: var(--color-white);

                & .stopmarker-circle {
                    border-width: 0.2rem;
                }
            }

            & .stopmarker-circle {
                position: absolute;
                font-size: var(--text-size-xs);
                box-shadow: none;

                width: 3.2rem;
                height: 3.2rem;

                &:first-child {
                    left: 0.2rem;
                }

                &:nth-child(2) {
                    left: 1.4rem;
                }

                &.gradient-background {
                    background: conic-gradient(
                        var(--color-earth-900) 12.5%,
                        var(--color-galaxy-500) 25%,
                        var(--color-mars-600) 37.5%,
                        var(--color-venus) 50%,
                        var(--color-saturn-700) 62.5%,
                        var(--color-aurora-400) 75%,
                        var(--color-meteor-40) 87.5%,
                        var(--color-neptune-950) 100%
                    );
                }
            }
        }

        &:hover {
            & .stopmarker-circle {
                cursor: pointer;
            }
        }
    }
}
