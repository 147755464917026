.customer-search-result {
    padding: 2rem 1.6rem;
    outline: none;
    border: none;
    background-color: transparent;
    align-items: flex-start;
}
.customer-search-result:hover {
    background-color: var(--color-neptune-100);
    cursor: pointer;

    &:first-child {
        border-top-left-radius: var(--border-radius-l);
        border-top-right-radius: var(--border-radius-l);
    }

    &:last-child {
        border-bottom-left-radius: var(--border-radius-l);
        border-bottom-right-radius: var(--border-radius-l);
    }
}

.customer-search-result__address {
    text-align: left;
}

.customer-search-result__icon {
    flex-shrink: 0;
}
