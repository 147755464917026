.operational-tab-content {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    column-gap: 2.4rem;
    row-gap: 2rem;

    &__date-container {
        grid-column-start: 1;
        grid-column-end: 4;
    }

    &__date-label {
        column-gap: 0.4rem;
        margin-bottom: 0.8rem;
    }

    &__datepicker .react-datepicker__triangle {
        display: none;
    }

    &__radio-group-wrapper {
        grid-column-start: 4;
        grid-column-end: 7;

        & .radio-group__label {
            font-weight: var(--font-weight-medium);
        }
    }

    &__location-radio-group-wrapper {
        grid-column-start: 5;
        grid-column-end: 10;

        & .radio-group__label {
            font-weight: var(--font-weight-medium);
        }
    }

    &__radio-group {
        column-gap: 4rem;

        & .radio-group__radio-label {
            column-gap: 0.4rem;
            padding: 1.5rem;
            font-weight: var(--font-weight-normal);
            white-space: nowrap;
        }
    }

    &__radio-input {
        color: var(--color-sky);
    }

    &__operational-name {
        grid-column-start: 1;
        grid-column-end: 4;
        margin-top: 1.2rem;
    }

    &__customer-id {
        grid-column-start: 4;
        grid-column-end: 7;
    }

    &__service-time {
        grid-column-start: 7;
        grid-column-end: 10;
    }

    & .label-input {
        padding: 2.4rem;
    }

    &__external-task-type {
        grid-column-start: 1;
        grid-column-end: 5;
        .external-task-type-dropdown {
            padding: 1.4rem;
        }
    }

    .google-places-autocomplete {
        grid-column-start: 1;
        grid-column-end: 4;
    }

    .customer-database-autocomplete {
        & .fuzzytextsearch {
            width: inherit;
        }

        .fuzzytextsearch-input {
            height: 5rem;
        }

        .fuzzytextsearch-results {
            top: unset;
        }

        grid-column-start: 1;
        grid-column-end: 10;
    }

    &__address-line-1 {
        grid-column-start: 1;
        grid-column-end: 10;
    }

    &__address-line-2 {
        grid-column-start: 1;
        grid-column-end: 10;
        margin-top: -0.8rem;
    }

    &__city {
        grid-column-start: 1;
        grid-column-end: 4;
    }

    &__state {
        grid-column-start: 4;
        grid-column-end: 7;
    }

    &__zipcode {
        grid-column-start: 7;
        grid-column-end: 10;
    }

    &__collapsible {
        grid-column-start: 1;
        grid-column-end: 7;

        .collapsible-toggle_button {
            justify-content: start;
            padding: 0;

            .collapsible-toggle_text {
                padding: 0 0.8rem;
            }
        }

        .collapsible-panel {
            padding: 0;
            margin-top: 1.2rem;
        }
    }

    &__coordinates {
        column-gap: 2.4rem;
        margin-top: 2rem;
    }

    &__map-button {
        font-size: var(--text-size-m);
        padding: 0.8rem 1rem;
        margin: 0.8rem 0;
        grid-column-start: 1;
        grid-column-end: 4;
    }

    &__map {
        grid-column-start: 1;
        grid-column-end: 10;
        .customermap.map {
            height: 32rem;
        }
    }
}
