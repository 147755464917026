.detail-dropdown-field {
    border: solid 0.1rem transparent;
    border-radius: var(--border-radius-l);
    padding: 0.6rem 0.8rem;
    bottom: 0.7rem;
    height: 3rem;
    position: relative;

    .title-text {
        color: var(--color-galaxy-800);
    }

    &:hover {
        border-color: var(--color-ocean);
        background-color: var(--color-neptune-100);
    }

    .dropdown {
        display: block;
        min-height: 3rem;

        & .dropdown-show-items.down {
            top: inherit;
            right: inherit;
        }

        & .dropdown-item {
            padding: 1rem;
        }
    }
}

.detail-dropdown-field__inner-container {
    display: flex;
    align-items: center;
    cursor: pointer;
}
