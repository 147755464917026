@import 'src/ui/mixins/scrollbar';

.label-dropdown {
    row-gap: 0.8rem;
}

.label-dropdown__label {
    column-gap: 0.4rem;
}

.label-dropdown__asterisk {
    color: var(--color-ocean);
    font-size: var(--text-size-s);
    font-weight: var(--font-weight-medium);
}

.label-dropdown__inner-container {
    border: 0.1rem solid var(--color-galaxy-300);
    border-radius: 0.6rem;
    padding: 0 1.6rem;
    height: 3.2rem;
    font-weight: var(--font-weight-normal);

    &--open {
        border-color: var(--color-ocean);
        background-color: var(--color-neptune-100);
    }
}

.label-dropdown__menu-items {
    border-radius: var(--border-radius-m);
    max-height: 10rem;
    overflow-y: auto;
    @include thin-scrollbar;
}

.label-dropdown__menu-item {
    line-height: 1.6rem;
    font-weight: var(--font-weight-normal);
    padding: 0.8rem;
}
