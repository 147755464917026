.mark-end-of-shift-modal {
    min-width: 60.2rem;
    width: 60.2rem;
    min-height: 36rem;
    color: var(--color-galaxy-800);
}

.mark-end-of-shift-modal__wrapper {
    height: 36rem;
}

.mark-end-of-shift-modal__button {
    width: 11.7rem;
}

.mark-end-of-shift-modal__body {
    width: 100%;
    row-gap: 2.4rem;
}

.mark-end-of-shift-modal__toggle-section {
    border: 0.1rem solid var(--color-galaxy-100);
    border-radius: var(--border-radius-l);
    padding: 1.6rem;
    display: grid;
    grid-template-columns: 1fr 4rem;
    grid-template-rows: auto auto;
    gap: 0.4rem 1rem;
    grid-auto-flow: row;
    grid-template-areas:
        'title toggle'
        'description toggle';
}

.mark-end-of-shift-modal__toggle-section--on {
    background-color: var(--color-meteor-95-alt);
    border-color: transparent;
}

.mark-end-of-shift-modal__toggle {
    grid-area: toggle;
}

.mark-end-of-shift-modal__toggle-title {
    grid-area: title;
}

.mark-end-of-shift-modal__toggle-description {
    grid-area: description;
    font-size: var(--text-size-xs);
    line-height: 1.6rem;
    letter-spacing: 0.04rem;
}

.mark-end-of-shift-modal__title {
    white-space: pre-wrap;

    span {
        font-weight: var(--font-weight-bold);
    }
}

.mark-end-of-shift-modal__title,
.mark-end-of-shift-modal__description,
.mark-end-of-shift-modal__toggle-title {
    span {
        font-weight: var(--font-weight-bold);
    }
}

.mark-end-of-shift-modal__description,
.mark-end-of-shift-modal__toggle-title {
    font-size: var(--text-size-m);
    font-weight: var(--font-weight-medium);
    line-height: 2.4rem;
    letter-spacing: 0.015rem;
}
