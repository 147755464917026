.radiolistitem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem 1.6rem;
}

.radiolistitem-left {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.radiolistitem-text {
    font-size: 1.4rem;
    margin-left: 0.8rem;
}

.radiolistitem:hover {
    cursor: pointer;
}

.radiolistitem_selected,
.radiolistitem:hover {
    background-color: var(--color-galaxy-50);
}
