.checkbox {
    font-size: var(--text-size-s);
    display: inline-flex;
    align-items: center;
    margin: 0 0.8rem 0.8rem 0;

    &:hover {
        cursor: pointer;
    }
}

.checkbox--checked {
    color: var(--color-galaxy-800);
}

.checkbox--disabled {
    color: var(--color-galaxy-300);

    &:hover {
        cursor: not-allowed;
    }
}

.checkbox-box {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 2rem;
    height: 2rem;
    margin-right: 0.8rem;
    border-radius: 0.4rem;
    border: 0.1rem solid var(--color-galaxy-500);
    background-color: var(--color-comet);

    .checkbox--disabled & {
        opacity: 0.5;
    }

    .checkbox--checked & {
        background-color: var(--color-ocean);
        border-color: transparent;
    }
}
