.route-item__summary {
    & .routecard-metric,
    & .metric__units {
        color: var(--color-galaxy-800);
    }

    & .routecard-title,
    & .routecard-top_group {
        max-width: 30rem;
    }
}
