.compartment-edit-modal {
    height: 51.5rem;

    &__wrapper {
        padding: 0.8rem 2.8rem 1.6rem 0.4rem;
        height: inherit;

        & .dropdown-with-inputbox__outline {
            height: 4rem;
            padding: 1.2rem 1.6rem;
        }

        & .dropdown-with-inputbox__separator {
            height: 4rem;
        }

        & .dropdown-with-inputbox input {
            height: 4rem;
        }

        & .dropdown-with-inputbox__input-container {
            font-size: var(--text-size-m);
        }

        & .dropdown-selection {
            font-size: var(--text-size-m);
        }
    }

    &__body {
        height: 37rem;
        padding: 2rem;
        overflow: scroll;
    }

    &__footer {
        padding: 0;
        gap: 2rem;

        & button {
            padding: 1.2rem 3.2rem;
        }
    }
}
