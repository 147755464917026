@import 'src/ui/mixins/mapmarker';

.routemarker {
    @include mapmarker;
}

.routemarker-circle {
    @include mapmarker-circle(
        $circle-parent-name: routemarker,
        $circle-size: 4.8rem,
        $circle-hover-border-width: 0.4rem
    );
}

.routemarker-label {
    @include mapmarker-label($circle-parent-name: routemarker);
}

.routemarker-trip-label {
    font-weight: 400;
    position: absolute;
    top: -0.4rem;
    right: -2.4rem;
    padding: 0.2rem 0.4rem 0.1rem;
    border-radius: var(--border-radius-s);
    box-shadow: -0.2rem 0.1rem 0.2rem 0 var(--color-box-shadow);
}
