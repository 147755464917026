.stacked-stop-pin-popup {
    width: 27.1rem;
    height: 8rem;
    border-radius: var(--border-radius-l);
    z-index: var(--z-index-toast);

    position: absolute;

    &__stacked-stops {
        background-color: var(--color-galaxy-99);
        overflow-x: auto;
        overflow-y: hidden;

        white-space: nowrap;

        padding: 0.8rem;
        border-radius: var(--border-radius-l) var(--border-radius-l) 0 0;
        border-bottom: 0.1rem solid var(--color-galaxy-90);
        height: 4.8rem;

        display: flex;
        gap: 1.2rem;
        align-items: center;

        color: var(--color-galaxy-30);
        font-size: var(--text-size-s);
        font-weight: var(--font-weight-medium);
        line-height: 2rem;
        letter-spacing: var(--letter-spacing-xxxs);

        & .stopmarker {
            transform: none;

            & .stopmarker-body {
                width: 3.2rem;
                height: 3.2rem;

                &:hover {
                    cursor: pointer;
                }
            }

            &.stopmarker_selected {
                border: 0.1rem solid var(--color-black);
                border-radius: var(--dimension-round);

                & .stopmarker-body {
                    border-width: 0.1rem;
                }
                & .stopmarker-circle {
                    width: 2.4rem;
                    height: 2.4rem;
                }
            }
        }

        & .stopmarker-circle {
            width: 3.2rem;
            height: 3.2rem;
        }

        &__scrollable {
            height: 6.8rem;
        }
    }
}
