.add-operational-stop-modal {
    width: 84rem;
    max-height: calc(100vh - 11.8rem);
}

.add-operational-stop-modal > .modal-content-wrapper {
    max-height: calc(100vh - 11.8rem);
}

.add-operational-stop-modal__footer {
    height: 9.4rem;
    padding: 2.4rem 1.6rem;
    border-top: 0.1rem solid var(--color-galaxy-200);
}

.add-operational-stop-modal__cancel {
    padding: 2.4rem 5rem;
}

.add-operational-stop-modal__save {
    padding: 2.4rem 4rem;
}

.add-operational-stop-modal__content {
    font-size: var(--text-size-s);
    padding: 3.2rem;

    height: 100%;
    min-height: 0;
    overflow: auto;
}

.add-operational-stop-modal input {
    height: 3.2rem;
    font-size: var(--text-size-s);
    font-weight: 400;
}
