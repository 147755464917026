$font-size: xxxs, xxs, xs, s, m, l, xl, xxl;

@each $size in $font-size {
    ._fs-#{$size} {
        font-size: var(--text-size-#{$size});
    }
}

._fs-0 {
    font-size: 1.6rem;
}
