.marker-popup-footer {
    background-color: var(--color-galaxy-100);
    padding: 0.8rem;
    border-radius: 0 0 var(--border-radius-l) var(--border-radius-l);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.4rem;
    color: var(--color-galaxy-500-alt);
    text-align: center;
    font-size: var(--text-size-vxxs);
    font-weight: var(--font-weight-medium);
    line-height: 1.6rem;
    letter-spacing: var(--letter-spacing-l);
    cursor: default;

    &__arrow-icon {
        width: 1.8rem;
        height: 1.5rem;
        align-items: end;
    }
}
