@mixin text-modifier() {
    color: var(--color-galaxy-800);
    font-size: var(--text-size-xs);
    line-height: var(--text-size-m);
}

.inventory-list-item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;

    &__name {
        letter-spacing: var(--letter-spacing-m);
        width: 14rem;
        @include text-modifier();
    }

    &__value {
        @include text-modifier();
    }
}
