@mixin slider-track() {
    z-index: 5;
}

@mixin slider-thumb() {
    appearance: none;
    width: 1.6rem;
    height: 1.6rem;
    background: var(--color-ocean);
    border-radius: var(--border-radius-round);
    border: 0.2rem solid var(--color-comet);
    box-shadow: 0 0.1rem 0.5rem var(--color-box-shadow);
    cursor: pointer;

    &:hover {
        background-color: var(--color-ocean-500);
    }
}

@mixin disabled-slider-thumb() {
    cursor: not-allowed;
    background-color: var(--color-galaxy-400);
}

.slider-input {
    appearance: none;
    width: 100%;
    height: 0.5rem;
    border-radius: var(--border-radius-m);

    &::-webkit-slider-runnable-track {
        @include slider-track;
    }

    &::-moz-range-track {
        @include slider-track;
    }

    &::-webkit-slider-thumb {
        @include slider-thumb;
    }

    &::-moz-range-thumb {
        @include slider-thumb;
    }

    &:disabled {
        &::-webkit-slider-thumb {
            @include disabled-slider-thumb;
        }

        &::-moz-range-thumb {
            @include disabled-slider-thumb;
        }
    }
}

.divider-container {
    position: absolute;
    top: 0.9rem;
    width: 100%;
    padding-left: 0.7rem;
    padding-right: 0.7rem;
}

.divider {
    width: 0.2rem;
    height: 0.2rem;
    background: var(--color-white);
    border-radius: 50%;
}

.labels {
    margin-top: 0.5rem;
}
