.compartment-details {
    display: flex;
    width: 95%;
    min-width: 0;
    padding: 0.2rem 0;
    gap: 0.4rem;
    justify-content: space-between;

    & .compartment-details__label {
        flex-basis: 40%;
        flex-grow: 1;
        min-width: 0;
    }

    & .compartment-details__summary {
        flex-basis: 60%;
        width: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: right;
    }
}
