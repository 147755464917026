.duplicate-task-modal-actions {
    border-radius: var(--border-radius-l);
    box-shadow: 0 0.1rem 0.3rem 0 var(--color-box-shadow);
    background-color: var(--color-comet);
    gap: 0;

    .radio-group__label {
        font-size: var(--text-size-s);
        font-weight: var(--font-weight-medium);
        line-height: 2rem;
        letter-spacing: var(--letter-spacing-xxxs);
        padding: 2rem 1.2rem 2rem 2.4rem;
        gap: 0.8rem;

        &:first-of-type {
            border-bottom: 0.1rem solid var(--color-galaxy-90);
        }
    }

    &__optional {
        font-size: var(--text-size-s);
        font-weight: var(--font-weight-normal);
        line-height: 2rem;
        letter-spacing: var(--letter-spacing-xxxs);
    }

    .radio-container {
        align-self: flex-start;
    }

    .radio-group__radio-label-description {
        font-size: var(--text-size-xs);
        font-weight: var(--font-weight-normal);
        line-height: 1.6rem;
        letter-spacing: var(--letter-spacing-m);
        color: var(--color-galaxy-30);
    }

    .radio-group__radio-input-group {
        flex-direction: column;
    }
}
