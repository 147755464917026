.unarrive-selected-stop-modal {
    width: 52.4rem;
    &__header {
        padding: 1.6rem 2.4rem;
        height: auto;
    }
    &__modal-content {
        padding: 1.2rem 2rem 2rem 2rem;
        grid-auto-flow: 0.8rem;
        &__content-subject {
            padding: 1.6rem;
            &__text {
                gap: 0.8rem;
                font-size: var(--text-size-s);
                font-weight: var(--font-weight-normal);
                line-height: 2rem;
                letter-spacing: var(--letter-spacing-s);
                padding: 1.6rem 0;
                color: var(--color-galaxy-800);
            }
        }
        &__confirm-text {
            padding: 0 1.6rem;
            gap: 0.8rem;
            font-size: var(--text-size-s);
            font-weight: var(--font-weight-normal);
            line-height: 2rem;
            letter-spacing: var(--letter-spacing-s);
            color: var(--color-galaxy-800);
        }
    }
    &__footer {
        padding: 1.6rem 2.4rem;
        gap: 0.8rem;
        background-color: var(--color-comet);
        border-top: 0.1rem solid var(--color-galaxy-90);
        margin-top: auto;
        border-bottom-left-radius: var(--border-radius-l);
        border-bottom-right-radius: var(--border-radius-l);

        &__cancel,
        &__confirm {
            padding: 1.2rem 3.2rem;
        }
    }
}
