.routecard-marker {
    position: relative;
}

.routecard_select-button {
    width: 2.4rem;
    height: 2.4rem;
    color: inherit;

    &:not(.icon-button_text-over-icon) {
        border-radius: var(--dimension-round);
    }

    &:not([disabled]):hover {
        // button will have a preset background color attached to the marker
        // use `!important` to ensure that we can override the style attribute
        background-color: var(--color-comet) !important;

        border-color: var(--color-galaxy-500);

        * {
            display: none;
        }
    }
}

.routecard_selected {
    .routecard_select-button {
        &:not([disabled]):hover {
            * {
                display: flex;
            }

            svg {
                fill: var(--color-galaxy-500);
            }
        }
    }
}

.route-locked-icon__container {
    position: absolute;
    bottom: -35%;
    right: -30%;
    padding: 0.1rem 0.3rem 0.4rem 0.2rem;
    display: flex;
    align-items: center;
    justify-items: center;
    border: 0.1rem solid var(--color-white);
    border-radius: var(--dimension-round);
    background-color: var(--color-earth-60);
}
