.navigationtabsgroup {
    width: 100%;
    grid-area: navigationtabsgroup;
}

.navigationtabsgroup__button {
    padding: 1.2rem 0rem;
    color: var(--color-galaxy-800);
    background-color: var(--color-galaxy-100);
    box-shadow: 0rem 0rem 0rem transparent;
    font-size: var(--text-size-s);
    font-weight: var(--font-weight-normal);
    line-height: 2rem;
    cursor: pointer;
    width: 100%;
    border-width: 0rem;
    letter-spacing: 0.025rem;
}

.navigationtabsgroup__button:hover {
    background-color: var(--color-galaxy-50);
    border-top-color: var(--color-galaxy-50);
}

.navigationtabsgroup__button:first-child {
    border-top-left-radius: 0rem;
    border-bottom-left-radius: 0rem;
}

.navigationtabsgroup__button:nth-child(n + 2) {
    /* applying border left to all buttons except the first */
    background-image: linear-gradient(
        var(--color-galaxy-200),
        var(--color-galaxy-200)
    );
    background-repeat: no-repeat;
    background-size: 0.1rem 1.2rem;
    background-position: left center;
    /* applying border left to all buttons except the first */
}

.navigationtabsgroup__button:last-child {
    border-top-right-radius: 0rem;
    border-bottom-right-radius: 0rem;
}

.navigationtabsgroup__button[disabled] {
    color: var(--color-galaxy-800);
    cursor: no-drop;
}

.navigationtabsgroup__button[data-selected] {
    color: var(--color-galaxy-800);
    background-color: var(--color-white);
    cursor: default;
    border-top: 0.3rem solid var(--color-meteor);
    font-weight: var(--font-weight-medium);
    padding-top: 0.9rem;
}
