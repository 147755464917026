@import 'src/ui/mixins/stopscontrol-childmodal';

.multisearchable-dropdown__container {
    @include stopcontrol-childmodal-search;
    font-size: var(--text-size-s);

    & .multisearchable-dropdown__list {
        position: absolute;
        top: 4rem;
        left: 0;
        right: 0;
        min-height: 5rem;
        max-height: 14.5rem;
        background-color: var(--color-comet);
        box-shadow: 0 0.2rem 0.6rem rgba(24, 41, 65, 0.15);
        border-radius: var(--border-radius-l);
        overflow-y: auto;
        z-index: 1;

        /* for Firefox */
        min-height: 0;

        @include thin-scrollbar;
    }

    & .multisearchable-dropdown__list-item {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        font-size: var(--text-size-xs);
        padding: 0.8rem 1.2rem;
        background-color: var(--color-comet);
        text-align: left;
        border: none;
        border-radius: 0;

        &:hover {
            background-color: var(--color-neptune-100);
        }
    }
}
