:root {
    // font-weight
    --font-weight-thin: 100;
    --font-weight-ultra-light: 200;
    --font-weight-light: 300;
    --font-weight-normal: 400;
    --font-weight-medium: 500;
    --font-weight-semi-bold: 600;
    --font-weight-bold: 700;
    --font-weight-ultra-bold: 800;
    --font-weight-heavy: 900;
}
