.addTaskModal-taskInformationTab {
    & .time-windows-label_layout {
        display: flex;
        column-gap: 0.4rem;
    }

    & .time-window__container {
        position: relative;

        display: flex;
        justify-content: space-between;
        column-gap: 0.4rem;

        & .time-window__button--delete {
            position: absolute;
            left: calc(100% + 0.4rem);
            top: 50%;
            transform: translate(0, -50%);
        }

        & .react-datepicker {
            display: flex;
            flex-direction: column-reverse;
            .react-datepicker__triangle {
                display: none;
            }

            .react-datepicker__input-time-container {
                display: flex;
                flex-direction: column;
                margin: 0;
                padding: 1rem 1.2rem;

                .react-datepicker-time__caption {
                    margin-bottom: 0.5rem;
                }

                .react-datepicker-time__input-container {
                    .react-datepicker-time__input {
                        margin: 0;
                        width: 100%;

                        .react-datepicker-time__input {
                            margin: 0;
                            width: 100%;
                            padding: 2rem 1rem;
                        }
                    }
                }
            }
        }
    }
}

.slider {
    & .task-information-tab__slider {
        height: 0.5rem;
    }
}

.task-information-tab__row {
    column-gap: 8rem;
}

.task-information-tab__task-priority,
.task-information-tab__time-windows,
.task-information-tab__vehicle-type {
    row-gap: 0.8rem;
}

.task-information-tab__task-priority-label,
.task-information-tab__vehicle-type-label {
    column-gap: 0.4rem;
}
