@import 'src/ui/mixins/stopscontrol-childmodal';

.reassignmodal-header {
    @include stopcontrol-childmodal-header;
}

.reassignmodal-content {
    @include stopcontrol-childmodal-content;
}

.reassign-modal__trip-search,
.reassign-modal__route-search {
    font-size: var(--text-size-xs);
    gap: 0.4rem;
}

.reassignmodal-content-search {
    @include stopcontol-childmodal-content-search;
    margin-bottom: 0.4rem;
}
.reassignmodal-content-button {
    @include stopcontrol-childmodal-content-button;

    .icon {
        max-height: 1.4rem;
    }
}
