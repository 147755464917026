*,
::before,
::after {
    box-sizing: border-box;
}
html {
    font-size: 62.5%;
}
html,
body,
#root {
    height: 100%;
}
