.equipment-marker {
    transform: translate(-50%, -50%);
    background: var(--color-transparent);
    border: none;
    width: fit-content;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    .marker {
        border: 0.1rem solid var(--color-transparent);
        border-radius: 1rem;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--color-transparent);
        height: 4.2rem;
        position: relative;

        & .equipment-cluster-count {
            position: absolute;

            top: -0.5rem;
            right: 0.5rem;
            z-index: var(--z-index-modal);
            padding: 0 0.4rem;
            color: var(--color-white);
            background-color: var(--color-galaxy-800-alt);
            border-radius: var(--border-radius-xxl);
        }

        &__wrapper {
            background-color: var(--color-galaxy-100);
            border-radius: 0.8rem;
            border: 0.1rem solid var(--color-white);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            width: 3.6rem;
            height: 3.6rem;
        }

        &--selected {
            border-color: var(--color-galaxy-800);

            .marker__wrapper {
                background-color: var(--color-galaxy-100);
            }
        }

        &--single {
            width: 4.2rem;

            &.marker--selected {
                .marker__wrapper {
                    border-width: 0.3rem;
                }
            }
        }

        &--cluster {
            width: 6.4rem;

            .marker__wrapper:first-child {
                transform: translate(0.8rem, 0);
            }

            .marker__wrapper:nth-child(2) {
                transform: translate(-0.8rem, 0);
            }

            &.marker--selected {
                .marker__wrapper {
                    border-width: 0.1rem;
                }
            }
        }
    }

    .marker-label {
        font-size: var(--text-size-xs);
        width: fit-content;
        padding: 0 0.8rem;
        border-radius: var(--border-radius-xxxl);
        color: var(--galaxy-800);
        font-size: var(--text-size-xs);
        font-weight: var(--font-wright-medium);
        line-height: 1.6rem;
        letter-spacing: var(--letter-spacing-l);
        background-color: var(--color-comet);

        &--selected {
            background-color: var(--color-galaxy-800-alt);
            color: var(--color-white);
            margin-top: 0.15rem;
        }
    }

    &:hover {
        .marker__wrapper {
            background-color: var(--color-galaxy-90);
            border-width: 0.3rem;
        }

        .marker-label {
            background-color: var(--color-galaxy-100);
            color: var(--color-black);
        }

        .marker-label--selected {
            background-color: var(--color-galaxy-800-alt);
            color: var(--color-white);
        }
    }
}

.stack-pin-marker {
    & .stacked-equipment-pin-popup {
        top: -11.75rem;
        left: -13rem;

        &__scrollable {
            top: -13.75rem;
            height: 10rem;
        }
    }
}
