.add-break-modal__body {
    row-gap: 1.2rem;

    .icon_delete + span {
        color: var(--color-mars-500);
    }
}

.add-break-modal__header {
    > button {
        margin-left: auto;
    }
}

.add-break-modal__menu {
    background-color: var(--color-comet);
    border-radius: var(--border-radius-l);
}

.add-break-modal .add-break-modal-content__button {
    margin: 0 1.2rem 1.2rem;
}
