.multi-compartment-inputs {
    row-gap: 0.4rem;
}

.multi-compartment-inputs__title {
    font-weight: 500;
}

.multi-compartment-inputs__add-button {
    color: var(--color-ocean);
    background-color: transparent;
    border: none;

    &.tooltipbutton_m {
        padding: 0;
    }

    &:hover {
        color: var(--color-neptune-500);

        & svg {
            fill: var(--color-neptune-500);
        }
    }
}

.multi-compartment-inputs__content {
    row-gap: 1rem;
}

.multi-compartment-inputs__input-row .dropdown-with-inputbox {
    flex: 1;
}

.multi-compartment-inputs__delete-icon {
    position: absolute;
    right: -3rem;

    &:hover svg {
        fill: var(--color-mars);
    }
}
