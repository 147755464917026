.violation-badge {
    background: var(--color-white);
    border-radius: 2.7rem;
    padding: 0.3rem;
    top: 0.8rem;
    left: 1.7rem;

    & .badge-icon {
        margin: 0 0.2rem;
    }

    &.livestopmarker__icon .icon {
        height: 1.5rem;
        width: 1.5rem;
    }
}
