.manage-stop-control-list-item-metrics {
    gap: 0.4rem;

    > button {
        background-color: unset;
        width: auto;
        height: auto;
        column-gap: 0.4rem;
        margin-left: -0.3rem;

        .icon-button_text {
            position: relative;
            transform: unset;
            top: unset;
            left: unset;
            color: var(--color-galaxy-800-alt);
            font-size: var(--text-size-xs);
            font-weight: var(--font-weight-normal);
        }
    }
}
