@import 'src/ui/mixins/scrollbar';

.dragged-item {
    width: 100%;
    margin: 0.4rem;
    height: 10rem;
    font-size: var(--text-size-xxs);
    box-shadow: var(--color-box-shadow);
    background: var(--color-white);
    border-radius: var(--border-radius-l);
    border: 0.1rem solid var(--color-box-shadow);

    &--multiple {
        position: relative;
    }

    &__multiple-count {
        position: absolute;
        top: 1rem;
        right: 1.5rem;
        width: auto;
        padding: 0.5rem;
        font: var(--text-size-s);
        color: var(--color-white);
        background: var(--color-saturn-60);
        border-radius: var(--border-radius-l);
    }

    &__content {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 1rem;
        overflow: auto;

        @include thin-scrollbar;
    }
}
