.color-picker {
    &__container {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        row-gap: 0.6rem;
        column-gap: 0.6rem;
    }

    &__button {
        cursor: pointer;
        border-radius: var(--border-radius-round);
        outline: none;
        background: none;
        border: none;
    }

    &__reset-item {
        position: relative;
    }

    &__reset-line {
        position: absolute;
        height: 2.8rem;
        width: 0;
        border: 0.1rem solid var(--color-black);
        top: 0.1rem;
        left: 50%;
        transform: translate(-50%, 0) rotate(45deg);
    }

    &__reset-color {
        border-radius: var(--border-radius-round);
        background: white;
        border: 0.2rem solid var(--color-black);
        width: 3rem;
        height: 3rem;
    }
}
