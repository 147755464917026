.route-card-marker-with-hollow {
    width: 2.4rem;
    height: 2.4rem;
    border-radius: var(--border-radius-round);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0.2rem;
}

.select-button {
    width: 2rem;
    height: 2rem;
    background-color: var(--color-white);
    border-radius: var(--border-radius-round);
}
