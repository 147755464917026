.toasts-wrapper {
    position: fixed;
    top: 6.4rem;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;
    z-index: var(--z-index-toast);

    > * {
        pointer-events: all;

        &:not(:last-child) {
            margin-bottom: 0.8rem;
        }
    }

    .toast__icon-variant {
        min-width: var(--icon-s);
    }

    .toast__icon-cancel {
        min-width: var(--icon-s);
        margin-left: 0.8rem;
    }
}
