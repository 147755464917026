.expand-button__icon {
    position: absolute;

    &:hover {
        cursor: pointer;
    }

    &--disabled {
        cursor: not-allowed;

        svg {
            cursor: not-allowed;
        }
    }
}
