.sub-heading-1 {
    color: var(--color-galaxy-500);
    font-size: var(--text-size-s);
    font-weight: 400;
    line-height: 2rem;
}

.sub-heading-2 {
    color: var(--color-galaxy-500);
    font-size: var(--text-size-xs);
    line-height: 1.6rem;
}
