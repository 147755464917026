.shiftblock {
    margin-bottom: 1rem;
    border-radius: var(--border-radius-m);
    padding: 1rem;

    &.ishovered {
        margin-bottom: 1rem;
        background: var(--color-neptune-200);
    }
}

.shift-inputs {
    width: 26rem;
    padding: 0.6rem 1.2rem;
    border-radius: var(--border-radius-m);
    border: 0.1rem solid var(--color-galaxy-300);
    gap: 0.5rem;
    background: var(--color-white);
}

.shift-input {
    width: 8rem;
}

.shiftremovebutton {
    right: 2rem;
    top: 1rem;
}

.shiftslotremovebutton {
    right: 2rem;
    top: -1rem;
}

.shiftremovebutton,
.shiftslotremovebutton {
    &.hidden {
        visibility: hidden;
    }
    &.visible {
        visibility: visible;
    }
}

.shiftslots {
    gap: 2rem;
    flex-wrap: wrap;
}

.shiftslot {
    border-radius: var(--border-radius-m);
    padding: 0.8rem 1.2rem 0.8rem 0;
    row-gap: 0.4rem;
    flex: 1;
    max-width: 29rem;

    & + .shiftslot {
        padding-left: 1.2rem;
    }

    &.ishovered {
        background: var(--color-neptune-200);
    }
}

.addnewslot {
    gap: 0.4rem;
    font-size: var(--text-size-s);
    line-height: 2rem;
}

.shifttime {
    font-size: var(--text-size-m);
    line-height: 2.4rem;
    margin: 0;
}

.shiftduration {
    font-size: var(--text-size-s);
    line-height: 2rem;
    color: var(--color-galaxy-500);
    margin: 0;
}
