@mixin thin-scrollbar(
    $scrollbar-width: 0.4rem,
    $scrollbar-height: 0.8rem,
    $scrollbar-thumb-border-radius: var(--border-radius-l),
    $scrollbar-thumb-background-color: var(--color-galaxy-300),
    $scrollbar-thumb-hover-background-color: var(--color-galaxy-400),
    $scrollbar-track-background-color: var(--color-galaxy-90),
    $scrollbar-track-piece-background-color: var(--color-galaxy-90),
    $scrollbar-corner-background-color: var(--color-galaxy-90)
) {
    /*
     * The following styles override OS scroll bar settings. Required because the map has scroll focus so the
     * scroll bar in the list is hidden by the OS (for Chrome and Safari).
     */
    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: $scrollbar-width;
        height: $scrollbar-height;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: $scrollbar-thumb-border-radius;
        background-color: $scrollbar-thumb-background-color;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: $scrollbar-thumb-hover-background-color;
    }

    &::-webkit-scrollbar-track {
        background-color: $scrollbar-track-background-color;
    }

    &::-webkit-scrollbar-track-piece {
        background-color: $scrollbar-track-piece-background-color;
    }

    &::-webkit-scrollbar-corner {
        background-color: $scrollbar-corner-background-color;
    }
    /*
     * Firefox will show the scrollbar regardless of OS setting. The following styles are an approximation of
     * the above styles for Chrome and Safari using Firefox's support for scrollbar styling.
     */
    & {
        scrollbar-color: $scrollbar-thumb-background-color;
        scrollbar-width: thin;
    }
}
