.print-map-button {
    right: 2rem;
    top: 1rem;
    min-width: 12.9rem;
    height: 4.8rem;
    background: var(--color-comet);
    box-shadow: 0 0.2rem 0.6rem var(--color-box-shadow);
    border-radius: var(--border-radius-l);
    z-index: var(--z-index-map-controls);

    > button {
        background-color: unset;
        width: auto;
        height: auto;
        column-gap: 0.8rem;

        .icon-button_text {
            position: relative;
            transform: unset;
            top: unset;
            left: unset;
            color: var(--color-ocean);
            font-size: var(--text-size-m);
            font-weight: var(--font-weight-medium);
            line-height: 2.4rem;
        }
    }
}
