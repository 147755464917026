.inventoryTabContent {
    & .inventory-tab_header-container {
        display: flex;
        flex-direction: column;
        row-gap: 0.2rem;
    }

    & .inventory-tab_table-container {
        background-color: var(--color-galaxy-50);
        border-radius: 0.6rem;
        padding: 4.5rem 5.1rem;
        margin-top: 2rem;
    }

    & .add-new-row-button {
        margin-top: 1.2rem;

        & .button-layout {
            display: flex;
            flex-direction: row;
            align-items: center;
            column-gap: 0.4rem;
            color: var(--color-meteor);
        }
    }

    & .input-table {
        & th {
            text-align: left;
            font-weight: 400;
        }
    }
}
