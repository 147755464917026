.toggle {
    display: flex;
    flex-shrink: 0;
    height: 2.4rem;
    width: 4rem;
    border: none;
    padding: 0.1rem;
    border-radius: var(--border-radius-rounded-full);
    transition: background-color 0.15s ease-in-out;
    cursor: pointer;
    &--off {
        background-color: var(--color-galaxy-200);
    }
    &--on {
        background-color: var(--color-ocean);
    }
}

.toggle--disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.toggle__circle {
    width: 2.2rem;
    height: 2.2rem;
    border-radius: var(--border-radius-rounded-full);
    background-color: var(--color-comet);
    transition: transform 0.15s ease-in-out;
    box-shadow: 0px 1px 5px rgba(65, 145, 255, 0.2);
    &--off {
        transform: translateX(0rem);
    }
    &--on {
        transform: translateX(1.6rem);
    }
}
