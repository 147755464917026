.color-item {
    height: 3rem;
    width: 3rem;
    border-radius: var(--border-radius-round);

    &__inner-circle {
        height: 2.2rem;
        width: 2.2rem;
        border-radius: var(--border-radius-round);
    }

    &--selected {
        border: 0.2rem solid var(--color-neptune);
    }

    &:active:hover {
        border: 0.2rem solid var(--color-neptune);
    }

    &:hover {
        border: 0.2rem solid var(--color-galaxy-300);
    }
}
