.client-selector {
    &__wrapper {
        width: 100%;
        font-size: var(--text-size-m);
        gap: 1.2rem;
    }

    &__select-all {
        gap: 1.2rem;
        align-items: center;
    }

    &__items {
        flex-wrap: wrap;
        gap: 1.2rem;
    }
}
