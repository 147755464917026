.routecard-marker_no-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.8rem;
    height: 2.8rem;
    border-radius: 50%;
    font-size: 1.2rem;
    line-height: 1.6rem;
}
