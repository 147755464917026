.add-task-modal__add-item-button {
    padding: 0 0 0 0.8rem;
    min-width: fit-content;
    height: 2rem;
    justify-content: flex-end;
    column-gap: 0.4rem;

    .icon-button_text {
        color: var(--color-ocean);
        font-size: var(--text-size-xs);
        line-height: 2rem;
        transform: none;
        position: relative;
        top: unset;
        left: unset;
        align-self: flex-start;
    }
}
