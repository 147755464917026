@import 'src/ui/mixins/scrollbar';

.inventory-items-details {
    &__collapsible {
        margin: 0 -0.8rem 0 -1.5rem;

        .collapsible-toggle_text {
            text-decoration: underline;
            font-size: var(--text-size-xs);
            font-weight: var(--font-weight-medium);
            white-space: nowrap;
        }

        .collapsible-toggle_button {
            height: unset;
            margin-bottom: 1.2rem;
        }
    }

    &__item-group {
        display: flex;
        padding: 0 0.8rem 1.1rem 0.8rem;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
        align-self: stretch;
        max-height: 13.1rem;
        overflow: auto;

        @include thin-scrollbar;
    }
}

.inventory-header {
    display: flex;
    align-items: center;
    gap: 1.1rem;
}

.inventory-list {
    display: flex;
    width: 24rem;
    flex-direction: column;
    align-items: flex-start;
    border-radius: var(--border-radius-m);
    background: var(--color-galaxy-50);
}

.inventory-header-row {
    display: flex;
    padding: 1.1rem 0.8rem 0.8rem 0.8rem;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
}

.inventory-item-name {
    letter-spacing: var(--letter-spacing-m);
}
