.routeitem__tasks-prompt {
    gap: 0.4rem;

    & .routeitem__late-prompt {
        width: 1.4rem;
        justify-content: center;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(0.5rem, max-content));

        & .routeitem__late-icon {
            grid-row-start: 1;
        }

        & .routeitem__risk-icon {
            grid-column-start: -1;
        }
    }
}
