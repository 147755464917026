.compartment-tag {
    color: var(--color-galaxy-800);
    border: 0.1rem solid var(--color-galaxy-300);
    border-radius: var(--border-radius-m);
    font-size: var(--text-size-xs);
    white-space: nowrap;
    width: 14.25rem;

    &__label-unit {
        overflow: hidden;
        padding: 0.3rem 0.6rem;
        text-overflow: ellipsis;
        width: 65%;
        text-align: left;
    }

    &__separator {
        color: var(--color-galaxy-300);
    }

    &__capacity {
        overflow: hidden;
        padding: 0.3rem 0.6rem;
        text-overflow: ellipsis;
        text-align: end;
        width: 35%;
    }
}
