.detail-drawer-item {
    background-color: var(--color-comet);
    padding: 0 1.6rem 1rem 1.6rem;

    & + & {
        padding-top: 1rem;
    }
}

.detail-drawer-item__body {
    height: 0;
    transition: height 300ms, padding-top 300ms;
    overflow: hidden;
    margin-top: 0.5rem;
}

.detail-drawer-item__body .detail-content {
    font-size: var(--text-size-s);
    word-break: break-all;
    text-align: justify;
}

.detail-drawer-item__icon {
    min-width: var(--icon-s);
    min-height: var(--icon-s);
}

.detail-drawer-item--open .detail-drawer-item__body {
    height: fit-content;
    overflow: visible;
}

.detail-drawer-item__button {
    background: none;
    width: 100%;
    text-align: start;
    border: solid 0.1rem transparent;
    border-radius: var(--border-radius-l);
    padding: 0.6rem 0.8rem;
    position: relative;
    bottom: 0.7rem;
    min-height: 3rem;
}

.detail-drawer-item__button:hover {
    border-color: var(--color-galaxy-300);
    background-color: var(--color-galaxy-50);
    cursor: pointer;
}

.detail-drawer-item__button--disabled:hover {
    border-color: transparent;
    background-color: transparent;
    cursor: default;
}

.detail-drawer-item__divider {
    height: 1rem;
    background-color: var(--color-galaxy-50);
}
