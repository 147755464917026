.routecard-labels-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    column-gap: 0.8rem;
    row-gap: 0.4rem;

    li {
        font-size: var(--text-size-xs);
        line-height: 1.6rem;
        color: var(--color-galaxy-500);
        padding: 0.1rem 0.3rem;
        border: 1px solid var(--color-galaxy-300);
        border-radius: var(--border-radius-m);
        white-space: nowrap;
        max-width: 18rem;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .tooltip {
        word-break: break-all;
    }
}

.routecard-labels {
    .label__icon {
        padding-left: 0.4rem;
        min-width: max-content;
    }
}
