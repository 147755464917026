@import 'src/ui/mixins/scrollbar';

.manage-stop-control-multiple {
    row-gap: 1.2rem;
}

.manage-stop-control-multiple__stop-list-container {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
    gap: 0.8rem;
    padding: 0.8rem;
    border-radius: var(--border-radius-l);
    background: var(--color-comet);
}

.manage-stop-control-multiple__stop-list {
    max-height: 15.6rem;
    overflow-y: auto;

    /* for Firefox */
    min-height: 0;

    @include thin-scrollbar;

    * + & {
        margin-top: 1.2rem;
    }
}
