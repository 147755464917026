.customermap-controls {
    z-index: var(--z-index-map-controls);

    &_maptype {
        bottom: 1.6rem;
        left: 1.2rem;
    }

    &_zoom {
        bottom: 1.6rem;
        right: 1.2rem;

        & .zoom-column-in,
        .zoom-column-out {
            padding: 0.4rem;
        }
    }

    &_geofence {
        left: 1.2rem;
        top: 1.2rem;
    }
}

.customermap-button {
    font-size: var(--text-size-xs);
    margin-left: 0.8rem;
}

.customermap-slider {
    background-color: var(--color-comet);
    border-radius: var(--border-radius-l);
    padding: 1.4rem;
    margin-top: 1rem;
}

.customermap-geofence {
    background-color: var(--color-comet-alpha-70);
    backdrop-filter: blur(1.4rem);
    border-radius: var(--border-radius-l);
    padding: 1.2rem;
    font-weight: 500;
    min-width: 22rem;
}
