.icon {
    height: var(--icon-height, var(--icon-m));
    width: var(--icon-width, var(--icon-m));
    min-height: var(--icon-min-height, var(--icon-m));
    min-width: var(--icon-min-width, var(--icon-m));

    svg {
        height: 100%;
        width: 100%;
        stroke-width: 0.1rem;
    }

    &[disabled] svg {
        opacity: 0.4;
        cursor: default;
    }
}

@mixin set-icon-size($size: m) {
    --icon-height: var(--icon-#{$size});
    --icon-width: var(--icon-#{$size});
    --icon-min-height: var(--icon-#{$size});
    --icon-min-width: var(--icon-#{$size});
}

.icon_size-xxxs {
    @include set-icon-size(xxxs);
}
.icon_size-xxs {
    @include set-icon-size(xxs);
}
.icon_size-xs {
    @include set-icon-size(xs);
}
.icon_size-s {
    @include set-icon-size(s);
}
.icon_size-m {
    @include set-icon-size(m);
}
.icon_size-l {
    @include set-icon-size(l);
}
.icon_size-xl {
    @include set-icon-size(xl);
}
.icon_size-xxl {
    @include set-icon-size(xxl);
}
