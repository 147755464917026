.zoneeditmodal-container {
    height: 40rem;
    font-size: var(--text-size-m);

    .assignvehiclelabel {
        gap: 0.5rem;
    }

    & .zoneeditmodal-searchinput,
    .zoneeditmodal-searchresults {
        width: 100%;
    }

    & .zoneeditmodal-searchinput .fuzzytextsearch-input {
        height: 4.4rem;
        border-color: var(--color-galaxy-300);
    }

    & .fuzzytextsearch-results {
        top: initial;
        z-index: 1;
    }
}

.assignedvehiclelist {
    height: 20rem;
    overflow-y: auto;
    gap: 0.5rem;
}

.zoneedit-actions {
    padding: 3rem 3rem 2rem 0rem;
    gap: 2rem;

    & .action-button {
        padding: 0 2.4rem;
    }
}

.vehiclesearchresults-container {
    max-height: 20rem;
    overflow-y: auto;

    & .zoneeditmodal-searchresultitem {
        border: transparent;
        border-radius: 0;
    }

    & .zoneeditmodal-searchresultitem:hover {
        background: var(--color-neptune-100);
    }
}
