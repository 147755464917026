@import 'src/ui/mixins/scrollbar';

.duplicate-task-modal {
    &__content {
        overflow-y: auto;
        padding: 1.2rem 2rem 2rem;
        gap: 0.8rem;
        max-height: 28.9rem;
        background-color: var(--color-galaxy-99);
        flex: 1;

        @include thin-scrollbar;
    }

    &-footer {
        padding: 1.6rem 2.4rem;
        gap: 1.2rem;
        background-color: var(--color-comet);
        border-top: 0.1rem solid var(--color-galaxy-90);
        margin-top: auto;
        border-bottom-left-radius: var(--border-radius-l);
        border-bottom-right-radius: var(--border-radius-l);

        &__confirm {
            min-width: 12.3rem;
        }
    }
}
