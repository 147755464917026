@import 'src/ui/mixins/scrollbar';
@import 'src/ui/mixins/stopscontrol-childmodal';

.resequencemodal-header {
    @include stopcontrol-childmodal-header;
}

.resequencemodal-content {
    @include stopcontrol-childmodal-content;
}

.resequencemodal-content-search {
    @include stopcontol-childmodal-content-search;
}

.resequencemodal-content-button_disclaimer {
    border-radius: var(--border-radius-l);
    color: var(--color-mars-400);
    background-color: var(--color-mars-100);
    padding: 0.8rem;
}
.resequencemodal-content-button {
    @include stopcontrol-childmodal-content-button;
}

.resequencemodal-stopitem {
    display: flex;
    align-items: center;
    overflow-x: hidden;
    padding: 0.4rem 0.3rem 0.4rem 0;

    .stopitem-buttongroup {
        display: none;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
    }

    &:hover {
        cursor: pointer;
        background-color: var(--color-neptune-100);
    }

    &:hover .manage-stop-control-list-item__task-type {
        display: none;
    }

    &:hover .stopitem-buttongroup {
        display: flex;
    }

    & + & {
        margin-top: 0.8rem;
    }

    .stopitem-button {
        width: auto;
        height: auto;
    }
}

.resequence-modal-stop-item__left {
    .stopitem-title {
        max-width: 13rem;
        overflow: hidden;
    }
}

.resequencemodal-search {
    @include stopcontrol-childmodal-search;
}

.resequencemodal-search-dropwdown {
    @include stopcontrol-childmodal-search-dropwdown;
}

.resequencemodal-buttongroup {
    background-color: var(--color-comet);
    margin-top: 1.2rem;
    border-radius: var(--border-radius-l);
    display: flex;
    flex-direction: column;
}
