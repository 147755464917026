.violation-pill {
    flex: 1 0 calc(50% - 1rem);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.2rem 0.8rem;
    gap: 0.4rem;
    width: fit-content;
    height: 2rem;
    background: var(--color-mars-60);
    border-radius: var(--border-radius-xxxl);
}

.violation-pill__text {
    height: 1.6rem;
    letter-spacing: 0.05rem;
    font-weight: 500;
    font-size: 1.1rem;
    line-height: 1.6rem;
    color: var(--color-white);
}
