:root {
    // font sizes
    --text-size-xxxs: 0.8rem;
    --text-size-xxs: 1rem;
    --text-size-vxxs: 1.1rem;
    --text-size-xs: 1.2rem;
    --text-size-vs: 1.3rem;
    --text-size-s: 1.4rem;
    --text-size-m: 1.6rem;
    --text-size-xm: 2.2rem;
    --text-size-l: 2.4rem;
    --text-size-xl: 3.2rem;
    --text-size-xxl: 3.6rem;
    --text-size-xxxl: 4.8rem;

    // icons
    --icon-xxxs: 0.6rem;
    --icon-xxs: 1rem;
    --icon-xs: 1.2rem;
    --icon-s: 1.6rem;
    --icon-m: 2rem;
    --icon-l: 2.4rem;
    --icon-xl: 3.6rem;
    --icon-xxl: 4.8rem;

    // border-radius
    --border-radius-rounded-full: 9999px;
    --border-radius-round: 50%;
    --border-radius-cluster: 3.2rem;
    --border-radius-xxxl: 2.5rem;
    --border-radius-xxl: 2.2rem;
    --border-radius-xl: 1.6rem;
    --border-radius-l: 0.8rem;
    --border-radius-m: 0.4rem;
    --border-radius-s: 0.2rem;

    // letter-spacing
    --letter-spacing-xxxs: 0.01rem;
    --letter-spacing-xxs: 0.015rem;
    --letter-spacing-xs: 0.02rem;
    --letter-spacing-s: 0.025rem;
    --letter-spacing-m: 0.04rem;
    --letter-spacing-l: 0.05rem;
    --letter-spacing-xl: 0.1rem;
}
