.locked-route-warning-modal__footer {
    padding: 1rem;
    gap: 1rem;
}

.locked-route-warning-modal__content {
    font-size: var(--text-size-s);
    padding: 2.4rem 9.2rem 4.8rem 3.2rem;
    gap: 2rem;
    line-height: 1.8rem;

    & .routecard {
        background-color: var(--color-galaxy-50);
    }
}
