.singleitemselection > .dropdown {
    height: 7.4rem;

    .checkbox-box {
        margin-left: -2.8rem;
        visibility: hidden;
    }

    .dropdown-selections {
        z-index: 1;
    }

    > label {
        display: grid;
        grid-auto-flow: row;
        grid-auto-rows: 2.4rem max-content;
        grid-gap: 0.4rem;
        margin: 0;

        span {
            margin: 0;
        }
    }
}

.singleitemselection-error {
    margin-top: 1.1rem;
    gap: 1rem;

    span {
        color: var(--color-mars-500);
        font-size: var(--text-size-m);
        line-height: 2.4rem;
    }

    svg {
        margin-top: 0.3rem;
    }
}
