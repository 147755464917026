.add-task-modal__invoice {
    max-width: 30.8rem;
    > :first-of-type {
        margin-bottom: 3.1rem;
    }
}

.add-task-modal__invoice__row {
    margin-top: 0.8rem;

    > :first-child {
        min-width: 14.3rem;
        input {
            border-radius: 0.4rem 0 0 0.4rem;
            border-width: 0.1rem;
            border-right-width: 0.05rem;
        }
    }

    > :nth-child(2) {
        max-width: 9.8rem;
        input {
            border-radius: 0;
            border-width: 0.1rem;
            border-left-width: 0.05rem;
        }
    }

    > :nth-child(3) {
        height: 3.2rem;
        min-width: 5.7rem;
        place-items: center;
        border-radius: 0 0.4rem 0.4rem 0;
        align-self: end;
        background: var(--color-meteor-95-alt);
        border: 0.1rem solid var(--color-galaxy-90);
        border-left-width: 0;
    }

    > :nth-child(4) {
        right: -2.8rem;
    }
}
