@import 'src/ui/mixins/stopscontrol-childmodal';
.edit-break-duration-view__header {
    padding-bottom: 0.8rem;
    border-bottom: 0.1rem solid var(--color-galaxy-200);

    .icon_chevronDown {
        transform: rotate(90deg);
        cursor: pointer;
    }
}

.edit-break-duration-view__body {
    background-color: var(--color-comet);
    border-radius: var(--border-radius-l);

    .break-duration-content {
        padding-top: 1.2rem;
    }
}

.edit-break-duration-view__button {
    padding: 0 1.2rem 1.2rem 1.2rem;

    button {
        @include stopcontrol-childmodal-content-button;
    }

    :nth-child(2) {
        margin-top: 0.8rem;
    }
}
