@import 'src/ui/mixins/stopscontrol-childmodal';

.resequence-break-view__header {
    padding-bottom: 0.8rem;
    border-bottom: 0.1rem solid var(--color-galaxy-200);

    .icon_chevronDown {
        transform: rotate(90deg);
        cursor: pointer;
    }
}

.resequence-break-view__body {
    background-color: var(--color-comet);
    border-radius: var(--border-radius-l);
}

.resequence-break-view__button {
    padding: 0 1.2rem 1.2rem 1.2rem;

    button {
        @include stopcontrol-childmodal-content-button;
    }
}
