.stop-marker-list {
    &__all-button {
        width: 3.2rem;
        min-width: 3.2rem;
        height: 3.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;
        border: none;
        background-color: var(--color-transparent);
        cursor: pointer;
    }
}
