@import 'src/ui/mixins/mapmarker';

.assigned-stop-marker {
    @include mapmarker;
    transform: none;

    &__circle {
        @include mapmarker-circle($circle-parent-name: assigned-stop-marker);
        width: 3.2rem;
        height: 3.2rem;
        box-shadow: unset;
    }

    &__body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 3.6rem;
        height: 3.6rem;
    }

    &.stopmarker--selected {
        .assigned-stop-marker__body {
            border-radius: var(--dimension-round);
            border: 0.2rem solid var(--color-black);
            background-color: rgba(255, 255, 255, 0.8);
            padding: 0;
        }
    }
}
