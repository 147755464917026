.routecard {
    background-color: var(--color-comet);
    border: 0.1rem solid transparent;
    border-radius: var(--border-radius-l);
    padding: 0.8rem;
    column-gap: 1.2rem;
    box-shadow: 0rem 0.1rem 0.3rem var(--color-box-shadow);

    & + & {
        margin-top: 0.4rem;
    }

    &:hover {
        background-color: var(--color-galaxy-100);
    }

    &:focus {
        outline: none;
    }
}

.routecard_off {
    opacity: 0.75;
    background-color: var(--color-galaxy-50);

    svg {
        fill: var(--color-galaxy-400);
    }

    &,
    &:hover,
    .routeitem-main,
    .routeitem-main:hover,
    .routecard_select-button,
    .routecard_select-button:hover {
        cursor: not-allowed;
    }

    &.selected-cardstops--hoverable,
    &.selected-cardstops--hoverable:hover,
    &.selected-cardstops--hoverable *,
    &.selected-cardstops--hoverable *:hover {
        cursor: default;
    }
}

.routecard_selected {
    &,
    &:hover {
        background-color: var(--color-meteor-90);
    }

    .selected-cardstops_delivery,
    .selected-cardstops_pickup {
        background-color: var(--color-neptune-100);
    }
}
