.route-lines-control {
    bottom: 2rem;
    right: 8.42rem;
    z-index: var(--z-index-map-controls-underlay);
    padding: 0.4rem 1rem;
    height: 4rem;
    gap: 1rem;
    border-radius: var(--border-radius-l);
    background: var(--color-comet);
    box-shadow: 0 0.1rem 0.3rem 0 var(--color-box-shadow);

    &__divider {
        width: 0.1rem;
        background: var(--color-galaxy-200);
    }
}
