.equipment-details-popup {
    &.marker-popup {
        z-index: var(--z-index-toast);
        bottom: 3rem;

        display: flex;
        flex-direction: column;
        column-gap: 2rem;

        & .route-card-text {
            font-weight: var(--font-weight-bold);
        }

        & .routecard-text {
            max-width: 24rem;
            overflow: hidden;
        }

        & .task-type-chip {
            background-color: var(--color-galaxy-200);
            color: var(--color-galaxy-800-alt);
        }

        & .selected-card-item:nth-child(2) {
            & .routecard-text {
                max-width: 18rem;
                overflow: hidden;
            }

            & .task-type-chip {
                background-color: var(--color-galaxy-600-alt);
                color: white;
            }
        }

        & .route-card-text__id-with-status {
            & .route-card-text__content {
                max-width: 16rem;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }

        & .selected-card-item {
            & .route-card-text__content {
                max-width: 20rem;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        &.cluster-mode {
            bottom: 11rem;
            left: 13.5rem;
        }
    }
}
