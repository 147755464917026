.client-selector-item {
    width: 22rem;
    height: 12rem;
    background-color: var(--color-comet);
    border: 0.1rem solid var(--color-ocean);
    border-radius: var(--border-radius-l);
    padding: 1rem;
    justify-content: space-between;
    gap: 1.2rem;
    cursor: pointer;

    &__name {
        font-size: var(--text-size-m);
        font-weight: var(--font-weight-medium);
        line-height: 2.4rem;
        color: var(--color-galaxy-800);
    }
}
