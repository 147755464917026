.trip-card-summary {
    display: flex;
    gap: 0.2rem;
    flex-direction: column;
}

.trip-card-summary__metric-section {
    gap: 1rem;
    justify-content: space-between;
}

.trip-card-summary__metric-icon {
    width: 2rem;
    height: 2rem;
    text-align: center;
    border-radius: var(--border-radius-s);
    background: var(--color-galaxy-50);
    font-size: var(--text-size-s);
    color: var(--color-galaxy-800);
}

.trip-card-summary__general-metrics {
    justify-content: space-between;
}
