.compartment-usage-container {
    width: 100%;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 50% 50%;
    justify-items: start;
    margin: 0.4rem 0;
    border: 0.1rem solid var(--color-galaxy-500);
    border-radius: var(--border-radius-m);

    & > * {
        width: 100%;
        border-bottom: 0.1rem solid var(--color-galaxy-500);
        border-right: 0.1rem solid var(--color-galaxy-500);
    }

    & > *:nth-child(2n) {
        border-right: 0;
    }

    & > *:nth-child(2n-1):nth-last-of-type(1) {
        grid-column: span 2;
        border: 0;
    }

    & > *:nth-last-of-type(1) {
        border-bottom: 0;
    }

    & > *:nth-child(2n-1):nth-last-of-type(2) {
        border-bottom: 0;
    }
}
