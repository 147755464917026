.duplicate-task-modal-invoices {
    background: var(--color-galaxy-99);
    border-top: 0.1rem solid var(--color-galaxy-90);
    padding: 0.4rem;
    gap: 0.4rem;
    border-bottom-left-radius: var(--border-radius-l);
    border-bottom-right-radius: var(--border-radius-l);
}

.duplicate-task-modal-invoice {
    padding: 1.2rem;
    gap: 1.2rem;
    border-radius: var(--border-radius-l);
    box-shadow: 0 0.1rem 0.3rem 0 var(--color-box-shadow);
    background: var(--color-comet);
    font-size: var(--text-size-xs);
    font-weight: var(--font-weight-normal);
    line-height: 1.6rem;
    letter-spacing: var(--letter-spacing-l);
    color: var(--color-galaxy-800);

    &__amount {
        font-size: var(--text-size-s);
        font-weight: var(--font-weight-normal);
        line-height: 2rem;
        letter-spacing: var(--letter-spacing-xxxs);
    }

    &__units {
        color: var(--color-galaxy-500-alt);
    }

    &--selected {
        background: var(--color-meteor-90);

        .duplicate-task-modal-invoice__units {
            color: var(--color-galaxy-800);
        }
    }

    &__labels {
        gap: 0.4rem;
        padding: 0.4rem 0;
        min-height: 2.8rem;
        flex-wrap: wrap;
    }

    &__label {
        padding: 0.2rem 0.8rem;
        border-radius: var(--border-radius-xxxl);
        color: var(--color-galaxy-500-alt);
        font-size: var(--text-size-vxxs);
        font-weight: var(--font-weight-medium);
        line-height: 1.6rem;
        letter-spacing: var(--letter-spacing-l);
        border: 0.1rem solid var(--color-galaxy-90);
    }

    &--selected &__label {
        border: none;
        background: var(--color-meteor);
        color: var(--color-comet);
    }
}
