.routeitem__trip {
    & .routecard-metric,
    & .metric__units {
        color: var(--color-galaxy-800);
    }
}

.routeitem__trip--small {
    line-height: 2rem;
    column-gap: 0.8rem;
    color: var(--color-galaxy-80);
    padding: 0.4rem 0.8rem;
    border: 0.1rem solid var(--color-galaxy-90);
    border-radius: var(--border-radius-l);
}

.routeitem__trip--small .routecard-title {
    font-size: var(--text-size-xs);
}

.routeitem__trip--small .routecard_select-button {
    width: 1.6rem;
    height: 1.6rem;
}
