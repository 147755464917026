.map-view-selector {
    width: fit-content;
    gap: 0.4rem;
    border: none;
    padding: 0;
    background: var(--color-comet);

    &__icon {
        width: 3.9rem;
        height: 3rem;
        border-radius: var(--border-radius-m);
        background: var(--color-galaxy-50);

        .icon {
            width: fit-content;
            height: fit-content;
        }
    }

    &__text {
        color: var(--color-galaxy-400);
        font-size: var(--text-size-xs);
        font-weight: var(--font-weight-normal);
        line-height: 1.6rem;
    }

    &--selected &__icon {
        border: 0.07rem solid var(--color-meteor);
        background: var(--color-meteor-95-alt);

        svg {
            fill: var(--color-meteor);
        }
    }

    &:hover &__icon {
        background: var(--color-meteor-95-alt);
    }

    &:hover &__text,
    &--selected &__text {
        color: var(--color-meteor);
    }
}
